import {useTranslation} from 'react-i18next';
import {instanceToInstance} from 'class-transformer';
import {HighLevelSummary} from '@features/statusReport/HighLevelSummary';
import {SiteStats} from '@features/statusReport/SiteStats';
import {FindingsList} from '@features/statusReport/FindingsList';
import {Finding, StatusReportDetails} from '@shared-app/types';
import {Stats} from '@shared-app/types/StatusReportDetails';
import styles from './ReportSummary.module.scss';

interface ReportSummaryProps {
  report: StatusReportDetails;
  draftReport: StatusReportDetails;
  onChange: (report: StatusReportDetails) => void;
}

export function ReportSummary({report, draftReport, onChange}: ReportSummaryProps) {
  const {t} = useTranslation('statusReport');

  const handleSummaryChange = (summary: string) => {
    draftReport.summary = summary;
    onChange(draftReport);
  };

  const handleFindingsChange =
    (key: 'siteConditionsv2' | 'equipmentv2' | 'labourv2' | 'ongoingActivitiesv2') =>
    (findings: Finding[]) => {
      if (!draftReport) return;

      const newDraftReport = instanceToInstance(draftReport, {
        ignoreDecorators: true
      });
      newDraftReport[key] = findings;
      onChange(newDraftReport);
    };

  const handleStatsChange = (stats: Stats) => {
    if (!draftReport) return;

    const newDraftReport = instanceToInstance(draftReport, {
      ignoreDecorators: true
    });
    newDraftReport.stats = stats;
    onChange(newDraftReport);
  };

  return (
    <div className={styles.findings}>
      <HighLevelSummary
        summary={report.summary}
        onChange={handleSummaryChange}
        editable={!!draftReport}
      />
      <SiteStats report={report} onChange={handleStatsChange} editable={!!draftReport} />
      <FindingsList
        title={t('Site Conditions')}
        findings={report.siteConditionsv2}
        onChange={handleFindingsChange('siteConditionsv2')}
        editable={!!draftReport}
      />
      <FindingsList
        onChange={handleFindingsChange('labourv2')}
        title={t('Labor')}
        findings={report.labourv2}
        editable={!!draftReport}
      />
      <FindingsList
        onChange={handleFindingsChange('equipmentv2')}
        title={t('Equipment utilization')}
        findings={report.equipmentv2}
        editable={!!draftReport}
      />
      <FindingsList
        onChange={handleFindingsChange('ongoingActivitiesv2')}
        title={t('Ongoing Activities')}
        findings={report.ongoingActivitiesv2}
        editable={!!draftReport}
      />
    </div>
  );
}
