import {useTranslation} from 'react-i18next';
import {CameraNameUpdate} from '@features/cameraNameUpdate';
import styles from './CameraSettingsInfo.module.scss';
import {Camera} from '@shared/types/Camera';
import {CameraImageStatistics} from '@shared/types/CameraImageStatistics';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS} from '@shared/lib/constants';
import {useProjectTimezone} from '@entities/project';

interface CameraSettingsInfoProps {
  camera: Camera;
  cameraStatistics: CameraImageStatistics;
  camerasObject: {[id: string]: Camera};
}

export function CameraSettingsInfo({
  camera,
  cameraStatistics,
  camerasObject
}: CameraSettingsInfoProps) {
  const {t} = useTranslation('settings');
  const timezone = useProjectTimezone();

  return (
    <div>
      <div className={styles.cameraInfo}>
        <div className={styles.label}>{t('Camera Name')}:</div>
        <div>
          {<CameraNameUpdate cameraId={cameraStatistics.id} camerasObject={camerasObject} />}
        </div>

        <div className={styles.label}>{t('Camera ID')}:</div>
        <div>{camera.externalId}</div>

        <div className={styles.label}>{t('First Image')}:</div>
        <div>
          {cameraStatistics.firstImageOn
            ? formatInTimeZone(cameraStatistics.firstImageOn, timezone, DATE_FORMATS.DATE_TIME_24)
            : null}
        </div>

        <div className={styles.label}>{t('Last Image')}:</div>
        <div>
          {cameraStatistics.lastImageOn
            ? formatInTimeZone(cameraStatistics.lastImageOn, timezone, DATE_FORMATS.DATE_TIME_24)
            : null}
        </div>

        <div className={styles.label}>{t('Total Images')}:</div>
        <div>{cameraStatistics.totalImages}</div>
      </div>
    </div>
  );
}
