import styles from './TagCard.module.scss';
import classNames from 'classnames';
import TagIcon from '@shared/ui/TagIcon';
import {useTranslationTags} from '@shared/lib/hooks';
import {Tag} from '@shared/types/Tag';

interface TagCardProp {
  tag: Tag;
  onClickCallback: (tag: Tag) => void;
  className?: string;
  isSelected?: boolean;
}

export function TagCard({tag, onClickCallback, className, isSelected}: TagCardProp) {
  const {t} = useTranslationTags();

  const classes = classNames(styles.container, className, styles[`tagCard__${tag.type}`], {
    [styles[`tagCard__${tag.type}_selected`]]: isSelected
  });

  return (
    <button
      onClick={() => {
        onClickCallback(tag);
      }}
      className={classes}
    >
      <div className={styles.icon}>
        <TagIcon stringId={tag.key} />
      </div>
      <div className={styles.name}>{t('name', tag.key)}</div>
    </button>
  );
}
