import {Event, Gate} from '@entities/occupancy';
import {getHourlyEntryExitData} from '../lib/utils/getHourlyEntryExitData';
import {OCCUPANCY_HOURLY_ENTRY_EXIT_CHART} from '@shared-app/lib';
import {useMemo} from 'react';
import {useProjectTimezone} from '@entities/project';
import {useTranslation} from 'react-i18next';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS, ONE_DAY_IN_MS} from '@shared/lib/constants';
import {renderToString} from 'react-dom/server';
import {AxisChart, AxisChartOptions} from '@shared-app/ui';
import {Tooltip} from './Tooltip';
import {TFunction} from 'i18next';
import {Chip} from '@shared/ui/Chip';
import {CircleIcon} from '@shared/ui/icons';
import {DateRange} from '@shared/ui/Date';
import styles from './HourlyEntryExitChart.module.scss';

interface HourlyEntryExitChartProps {
  events: Event[];
  dateRange: DateRange;
  selectedGateId?: string;
  gates: Gate[];
  selectedGateName?: string;
  selectedGateColor?: string;
}

export function HourlyEntryExitChart({
  events,
  dateRange,
  selectedGateColor,
  selectedGateName,
  selectedGateId
}: HourlyEntryExitChartProps) {
  const {t} = useTranslation('occupancy');
  const timezone = useProjectTimezone();

  const data = useMemo(
    () => getHourlyEntryExitData(events, dateRange, timezone, selectedGateId),
    [events, dateRange, timezone, selectedGateId]
  );

  const series = useMemo(
    () => [
      {name: t('Net Flow'), data: data.netFlow, type: 'line'},
      {name: t('Entries'), data: data.entryCount, type: 'column'},
      {name: t('Exits'), data: data.exitCount.map(count => -count), type: 'column'}
    ],
    [data.entryCount, data.exitCount, data.netFlow, t]
  );

  const chartOptions = useMemo(
    () => getChartOptions(data.time, timezone, t, series, dateRange),
    [data.time, timezone, t, series, dateRange]
  );

  const chartClasses = useMemo(() => ({title: styles.title}), []);

  return (
    <div className={styles.container}>
      <AxisChart
        title={
          <span className={styles.title}>
            {t('Entries/Exits')}
            {selectedGateName && selectedGateColor && (
              <Chip
                className={styles.selectedGate}
                icon={<CircleIcon style={{color: selectedGateColor}} />}
                label={selectedGateName}
              />
            )}
          </span>
        }
        series={series}
        options={chartOptions}
        classes={chartClasses}
      />
    </div>
  );
}

const LABEL_COLORS = OCCUPANCY_HOURLY_ENTRY_EXIT_CHART.LABEL_COLORS;

function getChartOptions(
  timestamps: number[],
  timezone: string,
  t: TFunction,
  series: {data: number[]}[],
  dateRange: DateRange
): AxisChartOptions {
  const maxSeriesValue = Math.max(...series.map(({data}) => data).flat(1));
  const yAxisMaxValue = maxSeriesValue + Math.ceil(maxSeriesValue / 10);

  const dateRangeDifferenceInMS = dateRange.endDate.getTime() - dateRange.startDate.getTime();
  const labelFormat =
    dateRangeDifferenceInMS > ONE_DAY_IN_MS
      ? DATE_FORMATS.DATE_TIME_24_SHORT
      : DATE_FORMATS.TIME_24;

  return {
    chart: {
      stacked: true
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    labels: timestamps.map(timestamp => formatInTimeZone(timestamp, timezone, labelFormat)),
    stroke: {
      width: [3, 0, 0],
      curve: 'straight'
    },
    colors: [LABEL_COLORS.NET_FLOW, LABEL_COLORS.ENTRY, LABEL_COLORS.EXIT],
    xaxis: {
      labels: {
        rotateAlways: false,
        style: {
          cssClass: styles.xaxisLabel
        }
      }
    },
    yaxis: {
      max: yAxisMaxValue // this was done to get gap between max data point and final grid line. hovering that gap allows us to see tooltip
    },
    legend: {
      show: true,
      customLegendItems: [t('Net Flow'), t('Entries'), t('Exits')]
    },
    tooltip: {
      shared: true,
      hideEmptySeries: false,
      custom: options =>
        renderToString(<Tooltip options={options} timestamps={timestamps} timezone={timezone} />)
    }
  };
}
