import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {AuthService} from '@shared/lib/services';
import {AuthUser} from '@shared/types/User';
import {Hub} from 'aws-amplify/utils';

export function useAuth() {
  const [user, setUser] = useState<AuthUser>();
  const location = useLocation();
  const navigate = useNavigate();

  const getUser = async (pathname: string) => {
    const user = await AuthService.currentUser(pathname);
    setUser(user);
  };

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({payload}) => {
      if (payload.event === 'customOAuthState' && payload.data) {
        window.location.href = payload.data;
      }
    });

    return unsubscribe;
  }, [navigate]);

  useEffect(() => {
    getUser(location.pathname);
  }, [location.pathname]);

  return {
    user,
    logout: AuthService.logout
  };
}
