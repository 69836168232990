import {StatusReportDetails} from '@shared-app/types';
import {StatusReportImage} from './StatusReportImage';
import styles from './StatusReportImages.module.scss';

interface StatusReportImagesProps {
  report: StatusReportDetails;
}

export function StatusReportImages({report}: StatusReportImagesProps) {
  return (
    <div className={styles.images}>
      {report.images.map(imageId => (
        <StatusReportImage key={imageId} imageId={imageId} />
      ))}
    </div>
  );
}
