import {useState} from 'react';
import classNames from 'classnames';
import {plainToInstance} from 'class-transformer';
import {Finding} from '@shared-app/types';
import {FindingModel} from '@entities/statusReport';
import styles from './InputFindingListItem.module.scss';

interface InputFindingListItemProps {
  onAdd: (finding: Finding) => void;
  className: string;
  placeholder: string;
  indicator: null | 'warning';
}

export function InputFindingListItem({
  onAdd,
  className,
  indicator,
  placeholder
}: InputFindingListItemProps) {
  const [value, setValue] = useState('');

  const addValue = () => {
    const trimmedValue = value.trim();
    if (!trimmedValue) return;
    onAdd(
      plainToInstance(
        FindingModel,
        {message: trimmedValue, indicator, children: []},
        {ignoreDecorators: true}
      )
    );
    setValue('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      addValue();
    }
  };

  return (
    <li className={classNames(className, styles.addNewItem)}>
      <input
        onKeyDown={handleKeyDown}
        onBlur={addValue}
        className={styles.addInfoInput}
        type="text"
        onChange={event => setValue(event.target.value)}
        placeholder={placeholder}
        value={value}
      />
    </li>
  );
}
