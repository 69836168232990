import {LanguageSwitcher} from '@entities/LanguageSwitcher';
import styles from './Header.module.scss';
import {HeaderLogo, Profile} from '@widgets/header';

export default function Header() {
  return (
    <div className={styles.container}>
      <HeaderLogo />
      <div className={styles.actionsContainer}>
        <LanguageSwitcher />
        <Profile />
      </div>
    </div>
  );
}
