import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {Typography} from '@shared/ui/Typography';
import {PolygonIcon} from '@shared-app/ui/icons';
import styles from './ZoneCard.module.scss';

interface ZoneCardProps {
  title: string;
  description: string;
  onClick: () => void;
  selected: boolean;
  color: string;
}

export function ZoneCard({title, description, onClick, selected, color}: ZoneCardProps) {
  const {t} = useTranslation('settings');
  return (
    <div
      className={classNames(styles.card, {[styles.card__selected]: selected})}
      style={{borderTopColor: color, outlineColor: color}}
      onClick={onClick}
    >
      <Typography variant="subtitle2">{title}</Typography>
      <div className={styles.description}>{description}</div>
      {selected && (
        <div className={styles.selectedText}>
          <PolygonIcon />
          {t('To add new region, draw polygon covering the area.')}
        </div>
      )}
    </div>
  );
}
