import {ProjectHeader} from '@entities/project';
import styles from './Header.module.scss';
import {HeaderShortLogo, Profile} from '@widgets/header';
import {Project} from '@shared/types/Project';
import {LanguageSwitcher} from '@entities/LanguageSwitcher';

interface HeaderProps {
  project: Project;
}

export default function Header({project}: HeaderProps) {
  return (
    <div className={styles.container}>
      <HeaderShortLogo />
      <ProjectHeader className={styles.projectHeader} project={project} />
      <div className={styles.actionsContainer}>
        <LanguageSwitcher />
        <Profile />
      </div>
    </div>
  );
}
