import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider, TimePicker} from '@mui/x-date-pickers';
import {format, parse} from 'date-fns';
import styles from './StatsTimeInput.module.scss';

interface TimeProps {
  value: string;
  reportDate: Date;
  editable: boolean;
  onChange: (value?: string) => void;
}

export function StatsTimeInput({value, reportDate, onChange, editable}: TimeProps) {
  const datetime = value ? parse(value, 'HH:mm', new Date(reportDate)) : null;

  const handleChange = (newValue: Date | null) => {
    if (!newValue || isNaN(newValue.getTime())) return onChange(undefined);

    onChange(format(newValue, 'HH:mm'));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        className={styles.root}
        value={datetime}
        onChange={handleChange}
        slots={{openPickerIcon: () => null}}
        format="HH:mm"
        readOnly={!editable}
      />
    </LocalizationProvider>
  );
}
