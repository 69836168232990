import {useMemo} from 'react';
import {FrequencyStatistic} from '@shared/types/FrequencyStatistic';
import {TimeRange} from '@shared/ui/TimeRange';
import TimelineChart from '@shared/ui/TimelineChart';
import {Typography} from '@shared/ui/Typography';
import {getHourRangeFromTimeRange, getTimelineChartOptions} from '@shared-app/lib';
import {useProjectTimezone} from '@entities/project';
import {useTranslation} from 'react-i18next';
import {zonedTimeToUtc} from 'date-fns-tz';
import styles from './FrequencyTimelineChart.module.scss';

interface Props {
  data: FrequencyStatistic[];
  timeRange: TimeRange;
}

export function FrequencyTimelineChart({data, timeRange}: Props) {
  const timezone = useProjectTimezone();
  const {t} = useTranslation('project');
  const series = useMemo(() => getSeries(data, timeRange, timezone), [data, timeRange, timezone]);
  const chartOptions = useMemo(() => getTimelineChartOptions(timezone), [timezone]);

  return (
    <div>
      <Typography variant={'h3'}>{t('Frequency timeline')}</Typography>
      <div>
        {series.length > 0 ? (
          <TimelineChart series={series} options={chartOptions} timezone={timezone} />
        ) : (
          <div className={styles.empty}>{t('No timeline data')}</div>
        )}
      </div>
    </div>
  );
}

function getSeries(data: FrequencyStatistic[], timeRange: TimeRange, timezone: string) {
  return getSeriesPerHour(data, timeRange, timezone);
}

function getSeriesPerHour(data: FrequencyStatistic[], timeRange: TimeRange, timezone: string) {
  const [startHour, endHour] = getHourRangeFromTimeRange(timeRange);

  return data.reduce(
    (hours, statistic) => {
      let continuityHours: number = 0;

      for (let hour = 0; hour <= statistic.data.length; hour++) {
        const detectedHours = statistic.data[hour];

        if (
          (!isWithinHourRange(hour, startHour, endHour) ||
            !areNumbersHaveSameSign(continuityHours, detectedHours)) &&
          continuityHours !== 0
        ) {
          const time = new Date(statistic.date);
          time.setHours(hour - Math.abs(continuityHours), 0, 0, 0);
          const nonzonedTime = zonedTimeToUtc(time, timezone);
          hours.push([nonzonedTime, continuityHours]);
          continuityHours = 0;
        }

        if (!isWithinHourRange(hour, startHour, endHour)) {
          continue;
        }

        continuityHours += detectedHours > 0 ? 1 : -1;
      }

      return hours;
    },
    [] as [Date, number][]
  );
}

function isWithinHourRange(hourToCheck: number, startHour: number, endHour: number) {
  return hourToCheck >= startHour && hourToCheck < endHour;
}

function areNumbersHaveSameSign(number1: number, number2: number) {
  return number1 <= 0 === number2 <= 0;
}
