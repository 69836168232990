import {searchableTags, useGetTagsGroupedByType} from '@entities/tag';
import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import {TagGroup} from '../TagGroup/TagGroup';
import styles from './TagFilters.module.scss';
import {useMemo} from 'react';
import {partitionBy} from '@conxai/react-kit';
import {PERSON_GROUP, PERSON_ONLY_GROUP, PersonTagGroup} from './PersonTagGroup';
import {TagTypeEnum} from '@shared/types/TagTypeEnum';

export function TagFilters({inclPPE}: {inclPPE: boolean}) {
  const {toggleTagInQuery} = useSmartSearchFilter();
  const {tagsGroupedByType} = useGetTagsGroupedByType(searchableTags);

  const [personGroups, otherGroups] = useMemo(
    () => partitionBy(tagsGroupedByType, item => item.type === TagTypeEnum.person),
    [tagsGroupedByType]
  );

  const personGroup = personGroups[0];

  return (
    <div className={styles.wrapper}>
      {personGroup && (
        <PersonTagGroup
          onTagClick={toggleTagInQuery}
          group={inclPPE ? PERSON_GROUP : PERSON_ONLY_GROUP}
        />
      )}
      {otherGroups.map(tagsGrouped => (
        <TagGroup tagsGrouped={tagsGrouped} key={tagsGrouped.type} onTagClick={toggleTagInQuery} />
      ))}
    </div>
  );
}
