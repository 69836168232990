import {useQuery} from '@tanstack/react-query';
import {ProjectService, useCurrentProject} from '@entities/project';
import {ToastService} from '@shared/lib/services';
import {useEffect} from 'react';

export function usePrivacySettings() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {data, isFetching, isRefetching, isError, error} = useQuery({
    queryKey: ['settings', 'privacy', projectId],
    queryFn: projectId ? () => ProjectService.getPrivacySettings(projectId) : undefined,
    enabled: !!projectId
  });

  const errorMessage = isError && error.message;

  useEffect(() => {
    if (errorMessage) {
      ToastService.error(errorMessage);
    }
  }, [errorMessage]);

  return {
    data,
    isError,
    isFetching: isFetching && !isRefetching
  };
}
