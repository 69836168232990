import {Expose, Transform, Type} from 'class-transformer';
import {
  Equipment,
  Finding,
  FindingsSummary,
  Labour,
  OngoingActivities,
  SiteConditions,
  Weather,
  StatusReportDetails,
  Stats,
  Stat,
  WeatherKey
} from '@shared-app/types/StatusReportDetails';

export class FindingModel implements Finding {
  @Expose({name: 'message'})
  message: string;

  @Expose({name: 'indicator'})
  indicator: number | null | ('check' | 'warning');

  @Expose({name: 'child_findings'})
  @Type(() => FindingModel)
  children: Finding[];
}

class FindingsSummaryModel implements FindingsSummary {
  @Expose({name: 'message'})
  message: string;

  @Expose({name: 'value'})
  value: number | null;
}

class SiteConditionsModel implements SiteConditions {
  @Expose({name: 'weather'})
  weather: WeatherKey;

  @Expose({name: 'findings_summary'})
  @Type(() => FindingsSummaryModel)
  summary: FindingsSummary[];

  @Expose({name: 'findings'})
  @Type(() => FindingModel)
  findings: Finding[];

  @Expose({name: 'n_issues'})
  numOfIssues: number;
}

class LabourModel implements Labour {
  @Expose({name: 'findings_summary'})
  @Type(() => FindingsSummaryModel)
  summary: FindingsSummary[];

  @Expose({name: 'findings'})
  @Type(() => FindingModel)
  findings: Finding[];

  @Expose({name: 'n_issues'})
  numOfIssues: number;
}

class EquipmentModel implements Equipment {
  @Expose({name: 'findings_summary'})
  @Type(() => FindingsSummaryModel)
  summary: FindingsSummary[];

  @Expose({name: 'findings'})
  @Type(() => FindingModel)
  findings: Finding[];

  @Expose({name: 'n_issues'})
  numOfIssues: number;
}

class OngoingActivitiesModel implements OngoingActivities {
  @Expose({name: 'findings_summary'})
  @Type(() => FindingsSummaryModel)
  summary: FindingsSummary[];

  @Expose({name: 'findings'})
  @Type(() => FindingModel)
  findings: Finding[];
}

class StatModel implements Stat {
  @Expose({name: 'message'})
  public label: string;

  @Expose({name: 'value'})
  public value: string;
}

class WeatherModel implements Weather {
  @Expose({name: 'condition'})
  condition: WeatherKey;

  @Expose({name: 'temperature'})
  temperature: string;

  @Expose({name: 'unit'})
  unit: string;
}

class StatsModel implements Stats {
  @Expose({name: 'section_1'})
  @Type(() => StatModel)
  section1: Stat[];

  @Expose({name: 'section_2'})
  @Type(() => StatModel)
  section2: Stat[];

  @Expose({name: 'weather'})
  @Type(() => WeatherModel)
  weather: Weather;
}

const sortFindings = ({value}: {value: Finding[]}) => {
  return value.sort((a, b) => {
    const value1 = a.indicator === null ? 0 : 1;
    const value2 = b.indicator === null ? 0 : 1;
    return value1 - value2;
  });
};

export class StatusReportDetailsModel implements StatusReportDetails {
  @Expose({name: 'summary'})
  public summary: string;

  @Expose({name: 'sources'})
  public sources: string[];

  @Expose({name: 'images'})
  public images: string[];

  @Expose({name: 'report_date'})
  @Transform(
    ({value, options}) => {
      if (options.ignoreDecorators) return value;
      return value.end;
    },
    {toClassOnly: true}
  )
  public reportDate: Date;

  @Expose()
  @Transform(
    ({obj, options, value}) => {
      if (options.ignoreDecorators) return value;
      return new Date(obj.report_date.start);
    },
    {toClassOnly: true}
  )
  public startTime: Date;

  @Expose()
  @Transform(
    ({obj, options, value}) => {
      if (options.ignoreDecorators) return value;
      return new Date(obj.report_date.end);
    },
    {toClassOnly: true}
  )
  public endTime: Date;

  @Expose({name: 'findings_summary'})
  @Type(() => StatsModel)
  public stats: Stats;

  @Type(() => SiteConditionsModel)
  public siteConditions: SiteConditions;

  @Expose({name: 'site_conditions'})
  @Type(() => FindingModel)
  @Transform(sortFindings)
  public siteConditionsv2: Finding[];

  @Type(() => LabourModel)
  public labour: Labour;

  @Type(() => EquipmentModel)
  public equipment: Equipment;

  @Type(() => OngoingActivitiesModel)
  public ongoingActivities: OngoingActivities;

  @Expose({name: 'labor'})
  @Type(() => FindingModel)
  @Transform(sortFindings)
  public labourv2: Finding[];

  @Expose({name: 'equipment'})
  @Type(() => FindingModel)
  @Transform(sortFindings)
  public equipmentv2: Finding[];

  @Expose({name: 'ongoing_activities'})
  @Type(() => FindingModel)
  @Transform(sortFindings)
  public ongoingActivitiesv2: Finding[];
}
