import {CSSProperties, useRef} from 'react';
import {useOnVisibleThumbnailUrl, usePlaceholderThumbnailUrl} from '@entities/smartImage';
import {SmartImage} from '@shared/types/SmartImage';
import classNames from 'classnames';
import {Image} from '@shared/ui';
import styles from './SmartImageThumbnail.module.scss';

export interface SmartImageThumbnailProps {
  image: SmartImage;
  onClick: (image: SmartImage) => void;
  style?: CSSProperties;
  isSelected?: boolean;
  className?: string;
}

export function SmartImageThumbnail({image, onClick, className}: SmartImageThumbnailProps) {
  const ref = useRef<HTMLDivElement>();

  const {placeholderThumbnailUrl} = usePlaceholderThumbnailUrl(image);
  const {thumbnailUrl, isLoading} = useOnVisibleThumbnailUrl(ref, image);

  return (
    <div
      className={classNames(styles.imageCard, className)}
      ref={ref}
      onClick={() => onClick(image)}
    >
      <Image
        src={isLoading ? placeholderThumbnailUrl : thumbnailUrl}
        alt={image.caption}
        isLoading={isLoading}
      />
    </div>
  );
}
