import {useEffect, useState} from 'react';
import {Tab, TabPanel, Tabs} from '@shared/ui/Tabs';
import {TagFilters} from '../TagFilters/TagFilters';
import styles from './SmartSearchFilter.module.scss';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {useSmartSearchFilterPanel} from '@features/smartSearchFilter/lib/state/useSmartSearchFilterPanel';
import {SmartSearchNavItem} from '../SmartSearchNavItem/SmartSearchNavItem';
import {SavedSearchList} from '@features/saveSearch/ui';
import {FeatureFlagKey} from '@shared-app/types/FeatureFlag';

interface SmartSearchFilterProps {
  featureFlagMap: Record<FeatureFlagKey, boolean>;
}

export function SmartSearchFilter({featureFlagMap}: SmartSearchFilterProps) {
  const {t} = useTranslation('search');
  const {show} = useSmartSearchFilterPanel();
  const [selectedTab, setSelectedTab] = useState(0);
  const [withAnimation, setWithAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(function () {
      setWithAnimation(true);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const containerClasses = classNames(
    styles.sidebar,
    {[styles.sidebar__show]: show},
    {[styles.sidebar__animated]: withAnimation}
  );

  return (
    <>
      <aside className={containerClasses}>
        <div className={styles.container}>
          <Tabs onChange={handleChange} value={selectedTab}>
            <Tab className={styles.tab} label={t('Tags', {ns: 'translation'})}></Tab>
            <Tab className={styles.tab} label={t('Saved Searches', {ns: 'search'})}></Tab>
          </Tabs>

          <TabPanel className={styles.tabPanel} selectedIndex={selectedTab} index={0}>
            <TagFilters inclPPE={featureFlagMap[FeatureFlagKey.SAFETY] !== false} />
          </TabPanel>
          <TabPanel className={styles.tabPanel} selectedIndex={selectedTab} index={1}>
            <SavedSearchList />
          </TabPanel>
        </div>
      </aside>
      <SmartSearchNavItem />
    </>
  );
}
