import {TagEnum} from '../../types/TagEnum';
import {TagTypeEnum} from '../../types/TagTypeEnum';
import {TFunction} from 'i18next';

export function trasnlationMapper(t: TFunction) {
  return {
    name: {
      [TagEnum.car]: t('Car'),
      [TagEnum.concrete_bucket]: t('Concrete Bucket'),
      [TagEnum.concrete_mixer]: t('Concrete Mixer'),
      [TagEnum.concrete_pump_boom]: t('Concrete Pump Boom'),
      [TagEnum.concrete_pump_hose]: t('Concrete Pump Hose'),
      [TagEnum.concrete_pump_truck]: t('Concrete Pump Truck'),
      [TagEnum.construction_forklift]: t('Construction Forklift'),
      [TagEnum.dump]: t('Dump'),
      [TagEnum.excavator]: t('Excavator'),
      [TagEnum.helmet]: t('Helmet'),
      [TagEnum.loader]: t('Loader'),
      [TagEnum.mobile_crane]: t('Mobile Crane'),
      [TagEnum.person]: t('Person'),
      [TagEnum.pickup]: t('Pickup'),
      [TagEnum.portable_toilet]: t('Portable Toilet'),
      [TagEnum.safety_vests]: t('Safety Vests'),
      [TagEnum.truck]: t('Truck'),
      [TagEnum.van]: t('Van'),
      [TagEnum.waste_container]: t('Waste Container'),
      [TagEnum.frame_formwork]: t('Frame Formwork'),
      [TagEnum.rebar]: t('Rebar'),
      [TagEnum.slab_formwork]: t('Slab Formwork'),
      [TagEnum.wet_concrete]: t('Wet Concrete'),
      [TagEnum.person_ppe_compliant]: t('Person PPE'),
      [TagEnum.person_ppe_violation]: t('Safety Violation'),
      [TagEnum.person_helmet_vest]: t('Person / PPE'),
      [TagEnum.person_no_helmet_no_vest]: t('Person / No PPE'),
      [TagEnum.person_no_helmet_vest]: t('Person / No Helmet'),
      [TagEnum.person_helmet_no_vest]: t('Person / No Vest'),

      [TagEnum.person_helmet]: t('Person / Helmet'),
      [TagEnum.person_vest]: t('Person / Vest'),
      [TagEnum.person_no_helmet]: t('Person / No Helmet'),
      [TagEnum.person_no_vest]: t('Person / No Vest'),

      [TagEnum.bulldozer]: t('Bulldozer'),
      [TagEnum.compactor]: t('Compactor'),
      [TagEnum.dump_truck]: t('Dump Truck'),
      [TagEnum.grader]: t('Grader'),
      [TagEnum.mixer_truck]: t('Mixer Truck'),
      [TagEnum.pickup_truck]: t('Pickup Truck'),
      [TagEnum.scraper]: t('Scraper'),
      [TagEnum.telescopic_handler]: t('Telescopic Handler'),
      [TagEnum.tower_crane]: t('Tower Crane'),
      [TagEnum.tractor]: t('Tractor'),
      [TagEnum.conex]: t('Conex'),
      [TagEnum.dumpster]: t('Dumpster'),
      [TagEnum.pylon]: t('Pylon'),
      [TagEnum.rebar_bundle]: t('Rebar Bundle'),
      [TagEnum.safety_vest]: t('Safety Vest'),
      [TagEnum.silo]: t('Silo'),
      [TagEnum.waste_bin]: t('Waste Bin'),
      [TagEnum.guardrail]: t('Guardrail'),
      [TagEnum.scaffolding]: t('Scaffolding'),
      [TagEnum.formwork_slab]: t('Formwork Slab'),
      [TagEnum.concrete_wet]: t('Concrete Wet'),
      [TagEnum.formwork_wall]: t('Formwork Wall'),
      [TagEnum.rebars]: t('Rebars')
    },
    description: {
      [TagEnum.car]: t('A car'),
      [TagEnum.concrete_bucket]: t(
        'A large metal container used to transport and pour concrete at a construction site'
      ),
      [TagEnum.concrete_mixer]: t(
        'A device that homogeneously combines cement, aggregate such as sand or gravel, and water to form concrete'
      ),
      [TagEnum.concrete_pump_boom]: t(
        'A large, articulating arm mounted on a truck, used to deliver concrete to hard-to-reach areas'
      ),
      [TagEnum.concrete_pump_hose]: t(
        'A flexible hose used to transport concrete from a pump to the pouring location'
      ),
      [TagEnum.concrete_pump_truck]: t(
        'A truck equipped with a machine to transfer liquid concrete through a pump using pipes and hoses'
      ),
      [TagEnum.construction_forklift]: t(
        'A powerful industrial vehicle with a pronged device in front for lifting and carrying heavy loads at construction sites'
      ),
      [TagEnum.dump]: t(
        'A truck with a large container that can tilt to unload its contents, commonly used for transporting loose material'
      ),
      [TagEnum.excavator]: t(
        'A heavy construction equipment consisting of a boom, dipper, bucket, and cab on a rotating platform, used for digging'
      ),
      [TagEnum.helmet]: t(
        'A protective helmet worn by construction workers to prevent head injuries'
      ),
      [TagEnum.loader]: t(
        'A heavy equipment machine used in construction to move aside or load materials such as asphalt, demolition debris, dirt, snow, feed, gravel, logs, raw minerals, recycled material, rock, sand, woodchips, etc'
      ),
      [TagEnum.mobile_crane]: t(
        'A crane capable of traveling over roads, designed to easily transport to a site and use for lifting heavy objects'
      ),
      [TagEnum.person]: t('An individual human being'),
      [TagEnum.pickup]: t('A light motor vehicle with an open-top rear cargo area'),
      [TagEnum.portable_toilet]: t(
        'A mobile toilet that can be easily moved around, often used at construction sites and outdoor events'
      ),
      [TagEnum.safety_vests]: t(
        'High-visibility vests worn by construction workers for safety and to be easily seen'
      ),
      [TagEnum.truck]: t('A large, heavy motor vehicle used for transporting goods or materials'),
      [TagEnum.van]: t(
        'A type of vehicle used for transporting goods or people, smaller than a truck and larger than a car'
      ),
      [TagEnum.waste_container]: t(
        'A container for temporarily storing waste, commonly seen in various sizes at construction sites'
      ),
      [TagEnum.frame_formwork]: t(
        'A temporary mold into which concrete is poured to form structural elements like walls and columns'
      ),
      [TagEnum.rebar]: t('Steel bars or mesh used to reinforce concrete in construction'),
      [TagEnum.slab_formwork]: t(
        'Molds used to shape and support concrete until it hardens, specifically for horizontal surfaces like floors and bridges'
      ),
      [TagEnum.wet_concrete]: t(
        'Freshly mixed concrete before it hardens, used for construction purposes'
      ),
      [TagEnum.bulldozer]: t('Bulldozer'),
      [TagEnum.compactor]: t('Compactor'),
      [TagEnum.dump_truck]: t('Dump Truck'),
      [TagEnum.grader]: t('Grader'),
      [TagEnum.mixer_truck]: t('Mixer Truck'),
      [TagEnum.pickup_truck]: t('Pickup Truck'),
      [TagEnum.scraper]: t('Scraper'),
      [TagEnum.telescopic_handler]: t('Telescopic Handler'),
      [TagEnum.tower_crane]: t('Tower Crane'),
      [TagEnum.tractor]: t('Tractor'),
      [TagEnum.conex]: t('Conex'),
      [TagEnum.dumpster]: t('Dumpster'),
      [TagEnum.pylon]: t('Pylon'),
      [TagEnum.rebar_bundle]: t('Rebar Bundle'),
      [TagEnum.safety_vest]: t('Safety Vest'),
      [TagEnum.silo]: t('Silo'),
      [TagEnum.waste_bin]: t('Waste Bin'),
      [TagEnum.guardrail]: t('Guardrail'),
      [TagEnum.scaffolding]: t('Scaffolding'),
      [TagEnum.formwork_slab]: t('Formwork Slab'),
      [TagEnum.concrete_wet]: t('Concrete Wet'),
      [TagEnum.formwork_wall]: t('Formwork Wall'),
      [TagEnum.rebars]: t('Rebars'),
      [TagEnum.person_ppe_compliant]: t('Person PPE'),
      [TagEnum.person_ppe_violation]: t('Safety Violation'),
      [TagEnum.person_helmet_vest]: t('Person / PPE'),
      [TagEnum.person_no_helmet_no_vest]: t('Person / No PPE'),
      [TagEnum.person_no_helmet_vest]: t('Person / No Helmet'),
      [TagEnum.person_helmet_no_vest]: t('Person / No Vest'),

      [TagEnum.person_helmet]: t('Person / Helmet'),
      [TagEnum.person_vest]: t('Person / Vest'),
      [TagEnum.person_no_helmet]: t('Person / No Helmet'),
      [TagEnum.person_no_vest]: t('Person / No Vest')
    },
    type: {
      [TagTypeEnum.equipment]: t('Equipment'),
      [TagTypeEnum.objectsAndTools]: t('Objects & tools'),
      [TagTypeEnum.structures]: t('Structures'),
      [TagTypeEnum.person]: t('Person'),
      [TagTypeEnum.hoisting]: t('Hoisting'),
      [TagTypeEnum.vehicle]: t('Vehicle')
    },
    other: {
      Helmet: 'Helmet',
      'Personal Protective Equipment': 'Personal Protective Equipment',
      'PPE Settings': 'PPE Settings',
      'Person wearing all selected attributes is considered to be using PPE':
        'Person wearing all selected attributes is considered to be using PPE',
      'Person not wearing any of selected attributes is considered to be violating safety protocol':
        'Person not wearing any of selected attributes is considered to be violating safety protocol'
    }
  };
}
