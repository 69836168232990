import {ReactNode} from 'react';
import {StatusReportDetails} from '@shared-app/types';
import {useTranslation} from 'react-i18next';
import {StatsTimeInput} from './StatsTimeInput';
import {StatsNumberInput} from './StatsNumberInput';
import styles from './SiteStats.module.scss';
import {Stats} from '@shared-app/types/StatusReportDetails';
import {instanceToInstance} from 'class-transformer';
import {replaceArrayItem} from '@shared/lib/utils';

interface SiteStats {
  report: StatusReportDetails;
  editable: boolean;
  onChange: (stats: Stats) => void;
}

export function SiteStats({report, editable, onChange}: SiteStats) {
  const {t} = useTranslation('statusReport');
  const {section1, section2} = report.stats;

  const handleChange = (section: 'section1' | 'section2', index: number) => (value: string) => {
    const updatedStats = instanceToInstance(report.stats, {ignoreDecorators: true});
    const updatedStat = instanceToInstance(updatedStats[section][index], {ignoreDecorators: true});
    updatedStat.value = value;
    updatedStats[section] = replaceArrayItem(updatedStats[section], index, updatedStat);
    onChange(updatedStats);
  };

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        {section1.map((stats, i) => (
          <Card
            key={stats.label}
            title={t(stats.label)}
            content={
              <StatsTimeInput
                value={stats.value}
                reportDate={report.reportDate}
                onChange={handleChange('section1', i)}
                editable={editable}
              />
            }
          />
        ))}
      </div>

      {/*<div className={styles.section}>*/}
      {/*  <Card*/}
      {/*    title={t('Weather conditions')}*/}
      {/*    content={*/}
      {/*      <span className={styles.weather}>*/}
      {/*        <WeatherIcon id={weather.condition} />*/}
      {/*        {weather.temperature} °{weather.unit.at(0).toUpperCase()}*/}
      {/*      </span>*/}
      {/*    }*/}
      {/*  />*/}
      {/*</div>*/}
      <div className={styles.section}>
        {section2.map((stats, i) => (
          <Card
            key={stats.label}
            title={t(stats.label)}
            content={
              <StatsNumberInput
                value={stats.value}
                editable={editable}
                onChange={handleChange('section2', i)}
              />
            }
          />
        ))}
      </div>
    </div>
  );
}

interface CardProps {
  title: string;
  content: ReactNode;
}

function Card({title, content}: CardProps) {
  return (
    <div className={styles.card}>
      <span className={styles.title}>{title}</span>
      <span className={styles.content}>{content}</span>
    </div>
  );
}
