import {useState} from 'react';
import {usePPEComplianceInfo} from '@entities/ppeDashboard';
import {ChartsSkeleton} from './ChartsSkeleton';
import {AbsoluteRelativeSelect, MeasuredAs} from '@features/ppeDashboard/AbsoluteRelativeSelect';
import {NonComplianceSummary} from '@features/ppeDashboard/NonComplianceSummary';
import {AttributeStatisticsPieChart} from '@features/ppeDashboard/AttributeStatisticsPieChart';
import {NonComplianceFrequencyByTimeHeatmap} from '@features/ppeDashboard/NonComplianceFrequencyByTimeHeatmap';
import {NonComplianceByDateChart} from '@features/ppeDashboard/NonComplianceByDateChart';
import {NonComplianceTypesByDateChart} from '@features/ppeDashboard/NonComplianceTypesByDateChart';
import {NonComplianceByWeekDayChart} from '@features/ppeDashboard/NonComplianceByWeekDayChart';
import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import styles from './Charts.module.scss';

export function Charts() {
  const [measuredAs, setMeasuredAs] = useState(MeasuredAs.RELATIVE);

  const {cameras, dateRange, timeRange} = useSmartSearchFilter();

  const {
    data: complianceInfo,
    isFetching,
    isError
  } = usePPEComplianceInfo({cameras, dateRange, timeRange});

  if (isFetching) {
    return <ChartsSkeleton />;
  }

  if (isError) {
    return 'Oops! Something went wrong';
  }

  const isRelative = measuredAs === MeasuredAs.RELATIVE;

  return (
    <div className={styles.container}>
      <div className={styles.summary}>
        <NonComplianceSummary complianceInfo={complianceInfo} />
        <AttributeStatisticsPieChart complianceInfo={complianceInfo} />
      </div>
      <div className={styles.remainingCharts}>
        <AbsoluteRelativeSelect value={measuredAs} onChange={setMeasuredAs} />
        <div className={styles.charts}>
          <NonComplianceByDateChart complianceInfo={complianceInfo} isRelative={isRelative} />
          <NonComplianceTypesByDateChart complianceInfo={complianceInfo} isRelative={isRelative} />
          <NonComplianceByWeekDayChart complianceInfo={complianceInfo} isRelative={isRelative} />
          <NonComplianceFrequencyByTimeHeatmap
            complianceInfo={complianceInfo}
            timeRange={timeRange}
            isRelative={isRelative}
          />
        </div>
      </div>
    </div>
  );
}
