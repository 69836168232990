import {useTranslation} from 'react-i18next';
import {useMutation} from '@shared/lib/hooks';
import {ProjectService, useCurrentProject} from '@entities/project';
import {ZoneData, ZoneSettings} from '@shared-app/types';
import {groupMapBy} from '@conxai/react-kit';

export function useUpdateZoneSettings(cameraId: string) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('settings');
  const listQueryOptions = {queryKey: ['projects', projectId, cameraId, 'zonesettings']};

  const {
    mutate: updateZoneSettings,
    isLoading: isSaving,
    isSuccess: isSaved
  } = useMutation<ZoneData[]>({
    mutationFn: zonesData => {
      const zoneSettings = groupMapBy(
        zonesData,
        zoneData => zoneData.zone,
        zoneData => zoneData.map(({polygon}) => polygon)
      ) satisfies ZoneSettings;
      return ProjectService.updateZoneSettings(projectId, cameraId, zoneSettings).catch(() => {
        throw new Error(t('Error occurred. Please try to save again.'));
      });
    },
    options: listQueryOptions
  });

  return {
    updateZoneSettings,
    isSaving,
    isSaved
  };
}
