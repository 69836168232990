import {useMemo} from 'react';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import {TimeSeriesAggregation, AxisChartOptions, TimeSeriesChart} from '@shared-app/ui';
import {ComplianceInfo} from '@entities/ppeDashboard';
import {getTotalNonComplianceByType} from '@entities/ppeDashboard';
import styles from './NonComplianceTypesByDateChart.module.scss';
import {NON_COMPLIANCE_TYPES_STACK_CHART} from '@shared-app/lib';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS} from '@shared/lib/constants';
import {useProjectTimezone} from '@entities/project';

interface Props {
  complianceInfo: ComplianceInfo[];
  isRelative: boolean;
}

export function NonComplianceTypesByDateChart({complianceInfo, isRelative}: Props) {
  const {t} = useTranslation('ppeDashboard');
  const timezone = useProjectTimezone();

  const seriesByType = useMemo(
    () => getTotalNonComplianceByType(complianceInfo, isRelative),
    [complianceInfo, isRelative]
  );

  const shouldShowWeekly = complianceInfo.length >= 21;

  return (
    <TimeSeriesChart
      title={
        shouldShowWeekly
          ? t('PPE Non-Compliance Types By Week')
          : t('PPE Non-Compliance Types By Date')
      }
      type="bar"
      series={[
        {name: t('No PPE'), data: seriesByType.noPPE},
        {name: t('Missing Vest'), data: seriesByType.noVest},
        {name: t('Missing Helmet'), data: seriesByType.noHelmet}
      ]}
      aggregation={shouldShowWeekly ? TimeSeriesAggregation.WEEKLY : undefined}
      options={getChartOptions(t, isRelative, seriesByType.noPPE, timezone, shouldShowWeekly)}
      isRelative={isRelative}
    />
  );
}

function getChartOptions(
  t: TFunction,
  isRelative: boolean,
  series: [number, number][],
  timezone: string,
  shouldShowWeekly: boolean
): AxisChartOptions {
  return {
    chart: {
      stacked: true
    },
    colors: NON_COMPLIANCE_TYPES_STACK_CHART.COLORS,
    legend: {
      customLegendItems: [t('No PPE'), t('Missing Vest'), t('Missing Helmet')]
    },
    ...(!shouldShowWeekly && {
      xaxis: {
        type: 'numeric',
        // default logic to show labels causing duplicates, so overriding it
        overwriteCategories: series.map(([timestamp]) =>
          formatInTimeZone(timestamp, timezone, DATE_FORMATS.DATE_ABBR_MONTH)
        )
      }
    }),
    yaxis: {
      title: (
        <div className={styles.yaxisTitle}>
          <div>{isRelative ? `(${t('% of man-hour')})` : `(${t('man-hour')})`}</div>
          <div>{t('Violations amount')}</div>
        </div>
      )
    }
  };
}
