import {GenerateStatusReportFormValues} from '../types/GenerateStatusFormValues';
import {Project} from '@shared/types/Project';
import {DATE_FORMATS} from '@shared/lib/constants';
import {formatInTimeZone} from 'date-fns-tz';
import {joinTruthyValues} from '@conxai/react-kit';

export function getGenerateReportRequestParams(
  formValues: GenerateStatusReportFormValues,
  project: Project,
  timezone: string
) {
  const {cameraId, language} = formValues;
  let date = formValues.date;

  const now = new Date();
  if (date > now) {
    date = now;
  }

  const cameraName = project.getCameraById(cameraId).name;
  const reportName = joinTruthyValues(
    [project.name, cameraName, formatInTimeZone(now, timezone, DATE_FORMATS.DATE_TIME_IN_FILE)],
    ' - '
  );

  return {date, cameraId, name: reportName, language, timezone};
}
