import {useState} from 'react';
import {FrequencyColumnsChart, FrequencyColumnsChartProps} from './FrequencyColumnsChart';
import FrequencyGroupByTabs from './FrequencyGroupByTabs';
import {WeekDayFrequencyColumnsChart} from './WeekDayFrequencyColumnsChart';
import {useTranslation} from 'react-i18next';

interface typeFrequencyColumnChartWrapperProps extends FrequencyColumnsChartProps {}

export function FrequencyColumnChartWrapper({
  data,
  timeRange
}: typeFrequencyColumnChartWrapperProps) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const {t} = useTranslation();

  return (
    <div>
      <FrequencyGroupByTabs
        title={t('Frequency detection')}
        selectedTabIndex={selectedTabIndex}
        onChange={setSelectedTabIndex}
      />
      {selectedTabIndex === 0 && <FrequencyColumnsChart data={data} timeRange={timeRange} />}
      {selectedTabIndex === 1 && <WeekDayFrequencyColumnsChart data={data} timeRange={timeRange} />}
    </div>
  );
}
