import {ZoneData} from '@shared-app/types';
import {Polygon, System} from 'detect-collisions';

export function checkPolygonsIntersect(polygons: ZoneData[]) {
  const system = new System();
  for (const polygon of polygons) {
    const polygonObject = new Polygon(
      {x: 0, y: 0},
      polygon.polygon.map(point => ({x: point[0], y: point[1]}))
    );

    system.insert(polygonObject);
  }
  return system.checkAll();
}
