import {FocusEvent, useMemo, useRef} from 'react';
import {instanceToInstance} from 'class-transformer';
import classNames from 'classnames';
import {Finding} from '@shared-app/types';
import {IconButton} from '@shared/ui/Button';
import {useTranslation} from 'react-i18next';
import {DeleteIcon} from '@shared/ui/icons';
import {ObservationList} from './ObservationList';
import {InputFindingListItem} from './InputFindingListItem';
import styles from './FindingsList.module.scss';
import {preventDefault} from '@shared-app/lib';

interface FindingsListProps {
  title: string;
  findings: Finding[];
  onChange: (findings: Finding[]) => void;
  editable?: boolean;
}

export function FindingsList({title, findings, onChange, editable}: FindingsListProps) {
  const ref = useRef<HTMLDivElement>();
  const {t} = useTranslation('statusReport');
  const infos = useMemo(() => findings.filter(finding => finding.indicator === null), [findings]);
  const observations = useMemo(
    () => findings.filter(finding => finding.indicator === 'warning'),
    [findings]
  );

  const handleAddItem = (finding: Finding) => {
    onChange([...infos, finding, ...observations]);
  };

  const handleObservationsChange = (observations: Finding[]) => {
    onChange([...infos, ...observations]);
  };

  const handleRemoveStatement = (index: number) => {
    onChange([...infos.slice(0, index), ...infos.slice(index + 1), ...observations]);
  };

  const handleStatementChange = (index: number) => (event: FocusEvent<HTMLSpanElement>) => {
    const value = event.target.textContent;

    const modified = infos[index];
    const modifiedInstance = instanceToInstance(modified);
    modifiedInstance.message = value;
    onChange([
      ...infos.slice(0, index),
      modifiedInstance,
      ...infos.slice(index + 1),
      ...observations
    ]);
  };

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.title}>{title}</div>
      <ul className={styles.infos}>
        {infos.map((info, index) => (
          <li className={classNames({[styles.editable]: editable})} key={index}>
            <span
              className={styles.message}
              data-id={index}
              contentEditable={editable}
              onBlur={handleStatementChange(index)}
              onDrop={preventDefault}
              suppressContentEditableWarning
            >
              {info.message}
            </span>
            {editable && (
              <IconButton
                className={styles.deleteButton}
                color="primary"
                onClick={() => handleRemoveStatement(index)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </li>
        ))}
        {editable && (
          <InputFindingListItem
            className={styles.editable}
            onAdd={handleAddItem}
            indicator={null}
            placeholder={t('Type here to add statement')}
          />
        )}
      </ul>

      <ObservationList
        observations={observations}
        onChange={handleObservationsChange}
        editable={editable}
      />
    </div>
  );
}
