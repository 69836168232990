import {useEffect, useRef} from 'react';
import confetti, {Options} from 'canvas-confetti';

export const useConfetti = (zIndex = 1999) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!canvasRef.current) {
      canvasRef.current = document.createElement('canvas');
      canvasRef.current.style.position = 'fixed';
      canvasRef.current.style.top = '0';
      canvasRef.current.style.left = '0';
      canvasRef.current.style.width = '100vw';
      canvasRef.current.style.height = '100vh';
      canvasRef.current.style.pointerEvents = 'none';
      canvasRef.current.style.zIndex = zIndex.toString();
      document.body.appendChild(canvasRef.current);
    }

    return () => {
      if (canvasRef.current) {
        document.body.removeChild(canvasRef.current);
        canvasRef.current = null;
      }
    };
  }, [zIndex]);

  const triggerConfetti = (options: Options = {}) => {
    if (canvasRef.current) {
      const myConfetti = confetti.create(canvasRef.current, {
        resize: true,
        useWorker: true
      });

      const count = 200;
      const defaults = {
        origin: {y: 0.7}
      };

      const fire = (particleRatio: number, opts: Options) => {
        myConfetti({
          ...defaults,
          ...opts,
          particleCount: Math.floor(count * particleRatio),
          ...options
        });
      };

      fire(0.25, {
        spread: 26,
        startVelocity: 55
      });
      fire(0.2, {
        spread: 60
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45
      });
    }
  };

  return triggerConfetti;
};
