import {pointInPolygon, Polygon} from 'detect-collisions';

export function checkPointInPolygon(polygon: [number, number][], point: [number, number]) {
  return pointInPolygon(
    {x: point[0], y: point[1]},
    new Polygon(
      {x: 0, y: 0},
      polygon.map(point => ({x: point[0], y: point[1]}))
    )
  );
}
