export function PolygonIcon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 7V17M19.5 7V17M7.5 5H17.5M7.5 19H17.5M3.5 4C3.5 3.73478 3.60536 3.48043 3.79289 3.29289C3.98043 3.10536 4.23478 3 4.5 3H6.5C6.76522 3 7.01957 3.10536 7.20711 3.29289C7.39464 3.48043 7.5 3.73478 7.5 4V6C7.5 6.26522 7.39464 6.51957 7.20711 6.70711C7.01957 6.89464 6.76522 7 6.5 7H4.5C4.23478 7 3.98043 6.89464 3.79289 6.70711C3.60536 6.51957 3.5 6.26522 3.5 6V4ZM17.5 4C17.5 3.73478 17.6054 3.48043 17.7929 3.29289C17.9804 3.10536 18.2348 3 18.5 3H20.5C20.7652 3 21.0196 3.10536 21.2071 3.29289C21.3946 3.48043 21.5 3.73478 21.5 4V6C21.5 6.26522 21.3946 6.51957 21.2071 6.70711C21.0196 6.89464 20.7652 7 20.5 7H18.5C18.2348 7 17.9804 6.89464 17.7929 6.70711C17.6054 6.51957 17.5 6.26522 17.5 6V4ZM17.5 18C17.5 17.7348 17.6054 17.4804 17.7929 17.2929C17.9804 17.1054 18.2348 17 18.5 17H20.5C20.7652 17 21.0196 17.1054 21.2071 17.2929C21.3946 17.4804 21.5 17.7348 21.5 18V20C21.5 20.2652 21.3946 20.5196 21.2071 20.7071C21.0196 20.8946 20.7652 21 20.5 21H18.5C18.2348 21 17.9804 20.8946 17.7929 20.7071C17.6054 20.5196 17.5 20.2652 17.5 20V18ZM3.5 18C3.5 17.7348 3.60536 17.4804 3.79289 17.2929C3.98043 17.1054 4.23478 17 4.5 17H6.5C6.76522 17 7.01957 17.1054 7.20711 17.2929C7.39464 17.4804 7.5 17.7348 7.5 18V20C7.5 20.2652 7.39464 20.5196 7.20711 20.7071C7.01957 20.8946 6.76522 21 6.5 21H4.5C4.23478 21 3.98043 20.8946 3.79289 20.7071C3.60536 20.5196 3.5 20.2652 3.5 20V18Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
