import {Typography} from '@shared/ui/Typography';
import {useTranslation} from 'react-i18next';
import Chart from 'react-apexcharts';
import {useMemo} from 'react';
import {Event, GatePercentage} from '@entities/occupancy';
import {Gate} from '@entities/occupancy';
import {ApexOptions} from 'apexcharts';
import {formatPercentage, groupMapBy, head} from '@conxai/react-kit';
import styles from './GrossFlowCard.module.scss';
import {Alert} from '@shared/ui/Alert';
import {OCCUPANCY_GROSS_FLOW_PIE_CHART} from '@shared-app/lib';

interface GrossFlowCardProps {
  events: Event[];
  gates: Gate[];
  onGateSelect: (g: string) => void;
  gateEventPercentages: GatePercentage[];
}

export function GrossFlowCard({gates, onGateSelect, gateEventPercentages}: GrossFlowCardProps) {
  const {t} = useTranslation('occupancy');

  const gatesById = useMemo(() => groupMapBy(gates, gate => gate.id, head), [gates]);
  const chartOptions = useMemo(
    () => getChartOptions(onGateSelect, gateEventPercentages, gatesById),
    [onGateSelect, gateEventPercentages, gatesById]
  );
  const series = useMemo(
    () => gateEventPercentages.map(({totalPercentage}) => totalPercentage),
    [gateEventPercentages]
  );

  return (
    <div className={styles.root}>
      <Typography variant="h3">{t('Gross Flow')}</Typography>
      <Alert severity="info" className={styles.selectInfo}>
        {t('Click on section to filter Hourly Entries/Exits chart')}
      </Alert>
      <Chart series={series} options={chartOptions} type={'donut'} height={180} width={200} />
      <div>
        {gateEventPercentages.map((gate, i) => {
          const {gateId, totalPercentage} = gate;
          return (
            <div key={gateId} className={styles.percentages}>
              <span className={styles.percentageLabel}>
                <span
                  className={styles.bulletPoint}
                  style={{
                    backgroundColor: OCCUPANCY_GROSS_FLOW_PIE_CHART.LABEL_COLORS[i]
                  }}
                ></span>
                {gatesById[gateId]?.name || gateId}
              </span>
              <span className={styles.percentageValue}>{formatPercentage(totalPercentage)}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function getChartOptions(
  onGateSelect: (g: string) => void,
  gatePercentagesByGateId: GatePercentage[],
  gatesById: Record<string, Gate>
): ApexOptions {
  return {
    chart: {
      events: {
        dataPointSelection: (event, chart, {dataPointIndex}: {dataPointIndex: number}) => {
          onGateSelect(gatePercentagesByGateId[dataPointIndex].gateId);
        }
      }
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {size: '50%'},
        offsetY: 10
      }
    },
    legend: {
      show: false
    },
    labels: gatePercentagesByGateId.map(({gateId}) => gatesById[gateId]?.name || gateId),
    colors: OCCUPANCY_GROSS_FLOW_PIE_CHART.LABEL_COLORS,
    yaxis: {
      labels: {
        formatter: formatPercentage
      }
    },
    dataLabels: {enabled: false}
  };
}
