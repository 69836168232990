import {ListItem, ListItemButton} from '@shared/ui/List';
import styles from './SettingsLayout.module.scss';
import {Link} from 'react-router-dom';

interface SettingsNavItemProps {
  children: string;
  to: string;
  selected: boolean;
}

export function SettingsNavItem({children, selected, to}: SettingsNavItemProps) {
  return (
    <ListItem disablePadding>
      <ListItemButton
        className={styles.menuButton}
        selected={selected}
        LinkComponent={Link}
        to={to}
      >
        {children}
      </ListItemButton>
    </ListItem>
  );
}
