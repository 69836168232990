import {useTranslation} from 'react-i18next';
import {trasnlationMapper} from '../utils/translationMapper';

export function useTranslationTags() {
  const {t} = useTranslation('tags');
  const {description, name, other, type} = trasnlationMapper(t);
  const translatorFn = (tagType: 'description' | 'name' | 'other' | 'type', key: string) => {
    switch (tagType) {
      case 'description':
        return description[key as keyof typeof description] || key;
      case 'name':
        return name[key as keyof typeof name] || key;
      case 'other':
        return other[key as keyof typeof other] || key;
      case 'type':
        return type[key as keyof typeof type] || key;
      default:
        return key;
    }
  };

  return {t: translatorFn};
}
