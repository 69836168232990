import Skeleton from '@shared/ui/Skeleton';
import styles from './CameraDetailsSkeleton.module.scss';

export function CameraDetailsSkeleton() {
  return (
    <div className={styles.container}>
      <Skeleton className={styles.cameraInfo} />
      <Skeleton className={styles.zoneConfiguration} />
    </div>
  );
}
