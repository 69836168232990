import {useQuery} from '@tanstack/react-query';
import {useCurrentProject} from '../state/useCurrentProject';
import {ProjectService} from '../services/Project.service';
import {useEffect, useMemo} from 'react';
import {ToastService} from '@shared/lib/services';
import {ZoneData} from '@shared-app/types';

export function useZoneSettings(cameraId: string) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {data, isFetching, isError, error} = useQuery({
    queryKey: ['projects', projectId, cameraId, 'zonesettings'],
    queryFn: () => ProjectService.getZoneSettings(projectId, cameraId)
  });

  const errorMessage = isError && error.message;

  useEffect(() => {
    if (errorMessage) {
      ToastService.error(errorMessage);
    }
  }, [errorMessage]);

  const polygons: ZoneData[] = useMemo(
    () =>
      data
        ? [
            ...data.ppe.map(polygon => ({zone: 'ppe', polygon}) satisfies ZoneData),
            ...data.exclusion.map(polygon => ({zone: 'exclusion', polygon}) satisfies ZoneData)
          ]
        : [],
    [data]
  );

  return {
    data: polygons,
    isFetching,
    isError
  };
}
