interface ConxaiGrayscaleProps {
  className?: string;
}

export function ConxaiGrayscale({className}: ConxaiGrayscaleProps) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 416.1 96.3"
      // style={{enableBackground: 'new 0 0 416.1 96.3'}}
      xmlSpace="preserve"
    >
      <path
        d="M164.6 78.6c-7.4 0-13-2-16.9-6.1-3.8-4.1-5.7-9.5-5.7-16.4 0-4.9.8-9.2 2.4-12.7 1.6-3.6 4.1-6.3 7.4-8.3 3.3-2 7.5-2.9 12.7-2.9 2.6 0 5 .2 7.1.6 2.2.4 4.2.9 6.1 1.5V41c-1.9-.7-3.9-1.3-6-1.7-2.1-.4-4.3-.6-6.7-.6-5.6 0-9.6 1.5-11.9 4.5-2.3 3-3.4 7.3-3.4 13 0 5.3 1.3 9.3 3.8 12 2.5 2.7 6.4 4.1 11.7 4.1 2.1 0 4.3-.2 6.4-.5 2.2-.4 4.2-.9 6.2-1.7v6.7c-1.9.6-3.9 1.1-6.1 1.4-2.2.2-4.5.4-7.1.4zm46.2 0c-7.1 0-12.5-1.9-16-5.6-3.5-3.8-5.3-9.6-5.3-17.5 0-8.2 1.8-14.2 5.3-17.8 3.5-3.7 8.9-5.5 16-5.5 7.2 0 12.5 1.8 16 5.5s5.3 9.6 5.3 17.8c0 7.9-1.8 13.7-5.3 17.5-3.5 3.7-8.9 5.6-16 5.6zm0-6.5c4.7 0 8.2-1.3 10.4-3.8 2.2-2.6 3.4-6.9 3.4-12.8 0-6.3-1.1-10.6-3.4-13.1-2.2-2.5-5.6-3.8-10.4-3.8-4.7 0-8.1 1.3-10.4 3.8-2.2 2.5-3.3 6.8-3.3 13.1 0 5.9 1.1 10.2 3.3 12.8 2.2 2.5 5.7 3.8 10.4 3.8zm35.8 5.8V32.8h6.7L276.8 66V32.8h7.6v45.1h-6.7l-23.5-33.1v33.1h-7.6zm52.2 0 16.1-22.5-15.8-22.6h8.8l11.5 16.7L331 32.8h8.9L324 55.4l16.1 22.5h-9l-11.7-16.5-11.7 16.5h-8.9zm52.4 0 17.2-45.1h8.5L394 77.9h-7.8l-4.5-11.6h-18.1l-4.5 11.6h-7.9zm13.5-17.5h15.7l-7.8-20.7-7.9 20.7zm43.8 17.5V32.8h7.6v45.1h-7.6z"
        style={{fill: '#636464'}}
      />
      <path style={{fill: '#636464'}} d="m0 85.1 25.3-15.5 22.2 12.7-25.3 14.3L0 85.1z" />
      <path style={{fill: '#a9a8a8'}} d="M0 85.1V34.8l22.2 12.7v49.1L0 85.1z" />
      <path style={{fill: '#cfcbcb'}} d="M0 34.8 50.7 0v25.3L22.2 47.5 0 34.8z" />
      <path style={{fill: '#636464'}} d="M100 84.9 74.7 69.4 52.5 82l25.3 14.2L100 84.9z" />
      <path style={{fill: '#a9a8a8'}} d="M100 84.9V34.6L77.8 47.2v49.1L100 84.9z" />
      <path style={{fill: '#cfcbcb'}} d="M100 34.6 50.7 0v25.3l27.2 21.9L100 34.6z" />
    </svg>
  );
}
