import {useCallback, useEffect, useState} from 'react';
import {useProjectTimezone} from '@entities/project';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {StatusReportDetails} from '@shared-app/types';
import {ToastService} from '@shared/lib/services';
import {useSaveStatusReport, useStatusReportDetails} from '@entities/statusReport';
import {StatusReportDetailsSkeleton} from './StatusReportDetailsSkeleton';
import {DetailsHeader} from '@widgets/statusReport/detailsHeader';
import {ReportSummary} from '@widgets/statusReport/reportSummary';
import {StatusReportImages} from '@features/statusReport/statusReportImages';
import styles from './StatusReportDetails.module.scss';
import {TFunction} from 'i18next';

export function StatusReportDetailsPage() {
  const timezone = useProjectTimezone();
  const {reportId} = useParams();
  const {t} = useTranslation('statusReport');
  const {report, isFetching} = useStatusReportDetails(reportId);
  const [draftedReport, setDraftedReport] = useState<StatusReportDetails>();
  const {isSaving, isSaved, saveReport} = useSaveStatusReport(reportId);

  const handleSave = useCallback(() => {
    const error = validateReport(draftedReport, t);
    if (error) {
      ToastService.error(error);
      return;
    }
    saveReport(draftedReport);
  }, [draftedReport, saveReport, t]);

  useEffect(() => {
    if (isSaved) {
      ToastService.success(t('Status report updated successfully'));
    }
  }, [isSaved, t]);

  useEffect(() => {
    if (report) {
      setDraftedReport(undefined); // we refetch updated report once we save it. resetting draft only after that to prevent stale UI
    }
  }, [report]);

  if (isFetching || !report) {
    return <StatusReportDetailsSkeleton />;
  }

  return (
    <div className={styles.container}>
      <DetailsHeader
        reportId={reportId}
        report={report}
        startTime={report.startTime}
        timezone={timezone}
        onEdit={() => setDraftedReport(report)}
        isEditing={!!draftedReport}
        onEditCancel={() => setDraftedReport(undefined)}
        onSave={handleSave}
        isSaving={isSaving}
      />
      <div></div>
      <ReportSummary
        report={draftedReport || report}
        onChange={setDraftedReport}
        draftReport={draftedReport}
      />
      <StatusReportImages report={report} />
    </div>
  );
}

function validateReport(draftedReport: StatusReportDetails, t: TFunction) {
  const invalidStat = draftedReport.stats.section1.find(stat => !stat.value);
  if (invalidStat) {
    return t(`Enter valid value for {{fieldName}}`, {fieldName: invalidStat.label});
  }
}
