import {Project} from '@shared/types/Project';
import {ProjectFilter} from '../ProjectFilter/ProjectFilter';
import {SaveSearchDialog} from '@features/saveSearch/ui';
import styles from './ProjectSearch.module.scss';
import {useBoolean} from '@conxai/react-kit';
import classNames from 'classnames';
import {DetectionQuery} from '../DetectionQuery/DetectionQuery';
import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import {Button} from '@shared/ui/Button';
import {useTranslation} from 'react-i18next';
import {ReplayIcon} from '@shared/ui/icons';
import {useEffect} from 'react';
import {ToastService} from '@shared/lib/services';

interface Props {
  project: Project;
  onScrollToTop?: () => void;
  currentDate?: Date;
  className?: string;
}

export function ProjectSearch({project, className}: Props) {
  const [isSaveDialogVisible, , showSaveDialog, hideSaveDialog] = useBoolean();
  const {reset, cameras} = useSmartSearchFilter();
  const {t} = useTranslation('search');

  const handleSave = () => {
    if (!cameras?.length) {
      ToastService.error(t('Select at least one camera'));
    } else {
      showSaveDialog();
    }
  };

  useEffect(() => {
    useSmartSearchFilter.persist.rehydrate();
  }, []);

  return (
    <>
      <div className={classNames(styles.filters, className)}>
        <DetectionQuery />
        <ProjectFilter project={project} />
        <div className={styles.actions}>
          <Button
            className={styles.resetBtn}
            size="small"
            onClick={reset}
            startIcon={<ReplayIcon />}
          >
            {t('Reset', {ns: 'translation'})}
          </Button>
          <Button className={styles.saveBtn} size="small" onClick={handleSave} variant="outlined">
            {t('Save Search')}
          </Button>
        </div>
      </div>
      {isSaveDialogVisible && (
        <SaveSearchDialog onClose={hideSaveDialog} cameras={project.cameras} />
      )}
    </>
  );
}
