import {Input} from '@mui/material';
import styles from './StatsNumberInput.module.scss';
import {useEffect, useState} from 'react';

interface StatsNumberInputProps {
  value: string;
  editable: boolean;
  onChange: (v: string) => void;
}

export function StatsNumberInput({value, editable, onChange}: StatsNumberInputProps) {
  const [state, setState] = useState(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
  };

  const handleBlur = () => {
    const num = Number(state);
    if (num < 0) {
      onChange(String(0));
      setState(String(0));
    } else if (num > 9999) {
      onChange(String(9999));
      setState(String(9999));
    } else {
      onChange(String(num));
      setState(String(num));
    }
  };

  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <Input
      className={styles.root}
      type="number"
      value={state}
      readOnly={!editable}
      inputProps={{max: 9999, min: 0}}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}
