import Skeleton from '@shared/ui/Skeleton';
import styles from './StatusReportDetailsSkeleton.module.scss';

export function StatusReportDetailsSkeleton() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleAndDates}>
          <Skeleton className={styles.title} />
          <Skeleton className={styles.date} />
          <Skeleton className={styles.date} />
        </div>
        <div className={styles.actions}>
          <Skeleton className={styles.button} />
          <Skeleton className={styles.button} />
        </div>
      </div>
      <div></div>
      <div className={styles.cards}>
        {Array.from({length: 6}).map((_, i) => (
          <Skeleton key={i} className={styles.card} />
        ))}
      </div>
      <div className={styles.images}>
        {Array.from({length: 6}).map((_, i) => (
          <Skeleton key={i} className={styles.image} />
        ))}
      </div>
    </div>
  );
}
