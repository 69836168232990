import {CSSProperties} from 'react';
import {SmartImageThumbnail} from '@entities/smartImage';
import {SmartImage} from '@shared/types/SmartImage';
import classNames from 'classnames';
import styles from './CarouselImage.module.scss';

export interface CarouselImageProps {
  image: SmartImage;
  onClick: () => void;
  style?: CSSProperties;
  isSelected?: boolean;
}

export function CarouselImage({image, onClick, isSelected}: CarouselImageProps) {
  return (
    <SmartImageThumbnail
      className={classNames({[styles.imageCard__selected]: isSelected})}
      image={image}
      onClick={onClick}
    />
  );
}
