import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';

import tagsEn from './en/tags.json';

import translationEn from './en/translation.json';
import searchEn from './en/search-translation.json';
import milestoneEn from './en/milestone-translation.json';
import userEn from './en/user-translation.json';
import projectEn from './en/project-translation.json';
import ppeDashboardEn from './en/ppe-dashboard.json';
import statusReportEn from './en/status-report.json';
import occupancyEn from './en/occupancy-translation.json';
import settingsEN from './en/settings-translation.json';

import tagsDe from './de/tags.json';
import translationDe from './de/translation.json';
import searchDe from './de/search-translation.json';
import milestoneDe from './de/milestone-translation.json';
import userDe from './de/user-translation.json';
import projectDe from './de/project-translation.json';
import ppeDashboardDe from './de/ppe-dashboard.json';
import statusReportDe from './de/status-report.json';
import occupancyDe from './de/occupancy-translation.json';
import settingsDE from './de/settings-translation.json';

import LanguageDetector from 'i18next-browser-languagedetector';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    resources: {
      en: {
        translation: translationEn,
        tags: tagsEn,
        milestone: milestoneEn,
        search: searchEn,
        user: userEn,
        project: projectEn,
        ppeDashboard: ppeDashboardEn,
        statusReport: statusReportEn,
        occupancy: occupancyEn,
        settings: settingsEN
      },
      de: {
        translation: translationDe,
        tags: tagsDe,
        milestone: milestoneDe,
        search: searchDe,
        user: userDe,
        project: projectDe,
        ppeDashboard: ppeDashboardDe,
        statusReport: statusReportDe,
        occupancy: occupancyDe,
        settings: settingsDE
      }
    },
    fallbackLng: 'en'
    // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
    // set returnNull to false (and also in the i18next.d.ts options)
    // returnNull: false,
  });
