export enum FeatureFlagKey {
  TAGS = 'tags',
  SAFETY = 'safety',
  STATUS_REPORT = 'reports',
  OCCUPANCY = 'occupancy',
  PRODUCTION_TRACKING = 'production_tracking'
}

export interface FeatureFlag {
  key: FeatureFlagKey;
  enabled: boolean;
}
