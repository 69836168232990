import {useEffect, useMemo, useState} from 'react';
import Konva from 'konva';
import {Stage, Layer, Image} from 'react-konva';
import {SmartImage} from '@shared/types/SmartImage';
import {useElementSize} from '@conxai/react-kit';
import {PolygonAnnotation} from './PolygonAnnotation';
import {useOriginalImageUrl} from '@entities/smartImage';
import {ZONE_COLORS} from '../lib/constants/zoneColors';
import {checkPointInPolygon} from '../lib/utils/checkPointInPolygon';
import {DeleteIcon} from '@shared/ui/icons';
import {BorderButton} from '@shared/ui/Button';
import {Zone, ZoneData} from '@shared-app/types';
import {ConfigurationCanvasSkeleton} from './ConfigurationCanvasSkeleton';
import styles from './ConfigurationCanvas.module.scss';

interface ConfigurationCanvasInnerProps {
  zone: Zone;
  zonesData: ZoneData[];
  setZonesData: (p: ZoneData[]) => void;
}

function ConfigurationCanvasInner({
  imageUrl,
  zone,
  zonesData,
  setZonesData
}: ConfigurationCanvasInnerProps & {imageUrl: string}) {
  const {ref, width, height} = useElementSize<HTMLDivElement>();
  const [selectedPolygon, setSelectedPolygon] = useState<number>(undefined);
  const [draftPoints, setDraftPoints] = useState<[number, number][]>([]);
  const [position, setPosition] = useState<[number, number]>([0, 0]);

  const [isMouseOverPoint, setMouseOverPoint] = useState(false);

  const imageElement = useMemo(() => {
    const element = new window.Image();
    element.src = imageUrl;
    return element;
  }, [imageUrl]);

  const scaleX = width / imageElement.naturalWidth;
  const scaleY = height / imageElement.naturalHeight;

  const getMousePos = (stage: Konva.Stage) => {
    return [
      Math.round(stage.getRelativePointerPosition()!.x),
      Math.round(stage.getRelativePointerPosition()!.y)
    ] as [number, number];
  };

  const handleDeleteSelectedPolygon = () => {
    setZonesData([...zonesData.slice(0, selectedPolygon), ...zonesData.slice(selectedPolygon + 1)]);
    setSelectedPolygon(undefined);
  };

  const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage!);

    if (draftPoints.length === 0) {
      const isOverlappedWithPolygon = zonesData.some(polygon =>
        checkPointInPolygon(polygon.polygon, mousePos)
      );
      if (isOverlappedWithPolygon) return;
      setDraftPoints([mousePos]);
      setSelectedPolygon(undefined); // doesn't make sense to old selection if drawing new
    } else if (isMouseOverPoint && draftPoints.length >= 3) {
      setZonesData([...zonesData, {zone, polygon: draftPoints}]);
      setDraftPoints([]);
    } else {
      setDraftPoints([...draftPoints, mousePos]);
    }
  };

  const handlePolygonClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
    setSelectedPolygon(Number(e.currentTarget.name()));
  };

  const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    if (!stage) return;
    const mousePos = getMousePos(stage);
    setPosition(mousePos);
  };

  const handleMouseOverStartPoint = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (!draftPoints.length || draftPoints.length < 3) return;
    e.target.scale({x: 2, y: 2});
    setMouseOverPoint(true);
  };

  const handleMouseOutStartPoint = (e: Konva.KonvaEventObject<MouseEvent>) => {
    e.target.scale({x: 1, y: 1});
    setMouseOverPoint(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      setDraftPoints([]);
    } else if (event.key === 'Backspace') {
      handleDeleteSelectedPolygon();
    }
  };

  useEffect(() => {
    if (zonesData) {
      // reset draft if parent resets zonesData
      setDraftPoints([]);
    }
  }, [zonesData]);

  const flattenDraftPoints = draftPoints
    .concat(draftPoints.length > 0 ? position : [])
    .reduce((a, b) => a.concat(b), [] satisfies number[]);

  const minScale = Math.min(scaleX, scaleY);

  return (
    <div className={styles.container} ref={ref} onKeyDown={handleKeyDown} tabIndex={-1}>
      <Stage
        className={styles.stage}
        width={imageElement.naturalWidth * minScale}
        height={imageElement.naturalHeight * minScale}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        scaleX={minScale}
        scaleY={minScale}
      >
        <Layer>
          <Image image={imageElement} x={0} y={0} />
          {zonesData.map(({zone, polygon}, index) => {
            const flattenedPoints = polygon.reduce<number[]>(
              (a, b) => a.concat(b),
              [] satisfies number[]
            );
            return (
              <PolygonAnnotation
                key={index}
                name={index.toString()}
                points={polygon}
                flattenedPoints={flattenedPoints}
                handleMouseOverStartPoint={handleMouseOverStartPoint}
                handleMouseOutStartPoint={handleMouseOutStartPoint}
                isFinished={true}
                fill={ZONE_COLORS[zone].fill}
                stroke={ZONE_COLORS[zone].stroke}
                onClick={draftPoints.length === 0 ? handlePolygonClick : undefined}
                selected={selectedPolygon === index}
              />
            );
          })}
          {draftPoints.length > 0 && (
            <PolygonAnnotation
              name={'draft'}
              points={draftPoints}
              flattenedPoints={flattenDraftPoints}
              handleMouseOverStartPoint={handleMouseOverStartPoint}
              handleMouseOutStartPoint={handleMouseOutStartPoint}
              isFinished={false}
              fill={ZONE_COLORS[zone].fill}
              stroke={ZONE_COLORS[zone].stroke}
              selected={true}
            />
          )}
        </Layer>
      </Stage>
      {selectedPolygon !== undefined && (
        <BorderButton className={styles.deleteButton} onClick={handleDeleteSelectedPolygon}>
          <DeleteIcon />
        </BorderButton>
      )}
    </div>
  );
}

export function ConfigurationCanvas({
  image,
  ...restProps
}: ConfigurationCanvasInnerProps & {image: SmartImage}) {
  const {imageUrl, isLoading} = useOriginalImageUrl(image);

  if (isLoading || !imageUrl) {
    return <ConfigurationCanvasSkeleton />;
  }

  return <ConfigurationCanvasInner imageUrl={imageUrl} {...restProps} />;
}
