import {useCurrentProject, useProjectImageStatistics, useProjectTimezone} from '@entities/project';
import {CameraImageStatisticsModel} from '@entities/project/model/CameraImageStatistics.model';
import {DATE_FORMATS, ROUTES} from '@shared/lib/constants';
import {Camera} from '@shared/types/Camera';
import {IconButton} from '@shared/ui/Button';
import {ColumnDef, SortItem, Table} from '@shared/ui/Table';
import {TFunction} from 'i18next';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CameraListSkeleton} from './CameraListSkeleton';
import styles from './CameraSettingsPage.module.scss';
import {IngestImageButton} from '@features/cameraImageIngest';
import {formatInTimeZone} from 'date-fns-tz';
import {EditNoteIcon} from '@shared/ui/icons';
import {NavigateFunction, useNavigate} from 'react-router-dom';

export function CameraSettingsPage() {
  const {project} = useCurrentProject();

  const {
    data: projectImageStatistics,
    isFetching: isFetchingProjectImageStatistics,
    isError
  } = useProjectImageStatistics();
  const timezone = useProjectTimezone();
  const {t} = useTranslation('settings');
  const navigate = useNavigate();

  const [sortModel, setSortModel] = useState<SortItem[]>([{field: 'date', sort: 'desc'}]);

  const camerasObject = useMemo(() => project.getCamerasObject(), [project]);

  const columns = useMemo(
    () => makeColumns(t, camerasObject, timezone, navigate, project.id),
    [t, camerasObject, timezone, navigate, project.id]
  );

  if (isFetchingProjectImageStatistics) {
    return (
      <div className={styles.container}>
        <CameraListSkeleton />
      </div>
    );
  }

  if (isError) {
    return t('Something went wrong', {ns: 'translation'});
  }

  return (
    <div className={styles.container}>
      <Table
        rows={projectImageStatistics.camerasStatistics}
        columns={columns}
        disableRowSelectionOnClick
        hideFooter
        hideFooterSelectedRowCount
        hideFooterPagination
        onSortModelChange={setSortModel}
        sortModel={sortModel}
      />
    </div>
  );
}

const makeColumns = (
  t: TFunction,
  camerasObject: {[k in string]: Camera},
  timezone: string,
  navigate: NavigateFunction,
  projectId: string
): ColumnDef[] => [
  {
    field: 'id',
    headerName: t('Camera Name'),
    flex: 1,
    renderCell: ({row: {id}}: {row: CameraImageStatisticsModel}) => camerasObject[id]?.name,
    sortComparator: (id1, id2) => {
      const cameraName1 = camerasObject[id1]?.name || '';
      const cameraName2 = camerasObject[id2]?.name || '';
      return cameraName1.toLowerCase().localeCompare(cameraName2.toLowerCase());
    }
  },
  {
    field: 'externalId',
    headerName: t('ID', {ns: 'translation'}),
    flex: 2,
    renderCell: ({row: {id}}) => {
      const externalId = camerasObject[id]?.externalId;
      return <div className={styles.externalId}>{externalId}</div>;
    }
  },
  {
    field: 'firstImageOn',
    headerName: t('First Image'),
    flex: 1,
    valueFormatter: firstImageOn =>
      firstImageOn ? formatInTimeZone(firstImageOn, timezone, DATE_FORMATS.DATE_TIME) : undefined
  },
  {
    field: 'lastImageOn',
    headerName: t('Last Image'),
    flex: 1,
    valueFormatter: lastImageOn =>
      lastImageOn ? formatInTimeZone(lastImageOn, timezone, DATE_FORMATS.DATE_TIME) : undefined
  },
  {
    field: 'ingest',
    headerName: t('Ingest'),
    renderCell: ({row: {id}}) => {
      const externalId = camerasObject[id]?.externalId;
      if (!externalId) return null;
      return <IngestImageButton cameraId={externalId} />;
    },
    flex: 1
  },
  {
    field: 'totalImages',
    headerName: t('Total Images'),
    align: 'right',
    flex: 1
  },
  {
    field: 'actions',
    headerName: '',
    width: 70,
    renderCell: ({row: {id}}) => {
      return ['development', 'local'].includes(process.env.ENV) ? (
        <IconButton
          onClick={() =>
            navigate(ROUTES.PROJECT.SETTINGS.CAMERA.DETAIL.withParams({cameraId: id, projectId}))
          }
        >
          <EditNoteIcon className={styles.editIcon} />
        </IconButton>
      ) : null;
    }
  }
];
