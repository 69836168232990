import {customerAPI, imageAPI, sitelensAPI, zoneSettingsAPI} from '@shared/api';
import ProjectModel from '@entities/project/model/Project.model';
import {plainToInstance} from 'class-transformer';
import {Project, ProjectRequest} from '@shared/types/Project';
import {ProjectImageStatistics} from '@shared/types/ProjectImageStatistics';
import {ProjectImageStatisticsModel} from '@entities/project/model/ProjectImageStatistics.model';
import {ZoneSettingsModel} from '@entities/project/model/ZoneSettings.model';
import {CameraModel} from '@entities/project';
import {PrivacySettings, PrivacyType, ZoneSettings} from '@shared-app/types';
import {PrivacySettingsModel} from '@entities/project/model/PrivacySettings.model';

export class ProjectService {
  public static async getProjects(): Promise<Project[]> {
    const projects = (await customerAPI.get('projects').json()) as Project[];

    return plainToInstance(ProjectModel, projects);
  }

  public static async getProjectStatistics(projectId: string): Promise<ProjectImageStatistics> {
    const projectImageStatistics = await sitelensAPI.get(`${projectId}/statistic/images`).json();

    return plainToInstance(ProjectImageStatisticsModel, projectImageStatistics);
  }

  public static async getPrivacySettings(projectId: string): Promise<PrivacySettings> {
    const privacySettings = await zoneSettingsAPI.get(`${projectId}/privacy-settings`).json();
    return plainToInstance(PrivacySettingsModel, privacySettings);
  }

  public static async updatePrivacySettings(
    projectId: string,
    settings: PrivacySettings
  ): Promise<PrivacySettings> {
    const privacyType = settings.privacyType;
    const opacity = privacyType ? settings.opacity : 0;
    const privacySettings = await zoneSettingsAPI
      .put(`${projectId}/privacy-settings`, {
        json: {
          classes: ['person'],
          opacity: opacity,
          color: [PrivacyType.CONTOUR_FILL, PrivacyType.BOX_FILL].includes(privacyType)
            ? settings.color
            : undefined,
          maskShape: privacyType
            ? [PrivacyType.CONTOUR_BLUR, PrivacyType.CONTOUR_FILL].includes(privacyType)
              ? 'contour'
              : 'box'
            : 'contour',
          maskType: privacyType
            ? [PrivacyType.BOX_FILL, PrivacyType.CONTOUR_FILL].includes(privacyType)
              ? 'fill'
              : 'blur'
            : 'blur'
        }
      })
      .json();

    return plainToInstance(PrivacySettingsModel, privacySettings);
  }

  public static async getZoneSettings(projectId: string, cameraId: string): Promise<ZoneSettings> {
    const zoneSettings = await zoneSettingsAPI
      .get(`${projectId}/cameras/${cameraId}/zone-settings`)
      .json();

    return plainToInstance(ZoneSettingsModel, zoneSettings);
  }

  public static async updateZoneSettings(
    projectId: string,
    cameraId: string,
    zoneSettings: ZoneSettings
  ) {
    await zoneSettingsAPI.put(`${projectId}/cameras/${cameraId}/zone-settings`, {
      json: {
        exclusion_zones: zoneSettings.exclusion,
        ppe_zones: zoneSettings.ppe
      }
    });
  }

  public static async updateProject(projectId: string, project: ProjectRequest) {
    const updatedProject = (await customerAPI
      .put(`project`, {
        json: {uuid: projectId, name: project.name, image_cover: project.coverImage}
      })
      .json()) as unknown;

    return plainToInstance(ProjectModel, updatedProject);
  }

  public static async deleteCoverImage(projectId: string) {
    (await customerAPI.delete(`projects/${projectId}/cover-image`)) as unknown;
  }

  public static async getProjectCameras(projectId: string) {
    const cameras = (await customerAPI.get(`projects/${projectId}/cameras`).json()) as unknown[];

    return plainToInstance(CameraModel, cameras);
  }

  public static async ingestCameraImage(projectId: string, cameraId: string, image: string) {
    return (await imageAPI.post(`api/v1/image`, {
      json: {image: image.split(',')[1], cameraId, projectId}
    })) as unknown;
  }

  public static async updateCameraName(cameraId: string, name: string) {
    const updatedCamera = (await customerAPI
      .put('camera', {
        json: {name: name, uuid: cameraId}
      })
      .json()) as unknown;

    return plainToInstance(CameraModel, updatedCamera);
  }
}
