import {useState} from 'react';
import {SmartImageThumbnail, useSmartImage} from '@entities/smartImage';
import {StatusReportImageSkeleton} from './StatusReportImageSkeleton';
import {SmartImageWithTagsModal} from '@features/smartImage/smartImageWithTagsModal';
import {SmartImage} from '@shared/types/SmartImage';
import {useCurrentProject, useProjectTimezone} from '@entities/project';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS} from '@shared/lib/constants';
import styles from './StatusReportImage.module.scss';

interface StatusReportImageProps {
  imageId: string;
}

export function StatusReportImage({imageId}: StatusReportImageProps) {
  const {data: image, isFetching} = useSmartImage(imageId);
  const [imageToShow, setImageToShow] = useState<SmartImage>();
  const {project} = useCurrentProject();
  const timezone = useProjectTimezone();

  if (isFetching) {
    return <StatusReportImageSkeleton />;
  }

  return (
    <div className={styles.container}>
      <span className={styles.time}>
        {formatInTimeZone(image.dateTime, timezone, DATE_FORMATS.TIME_24)}
      </span>
      <div>
        <SmartImageThumbnail className={styles.image} image={image} onClick={setImageToShow} />
      </div>
      {imageToShow ? (
        <SmartImageWithTagsModal
          image={imageToShow}
          onClose={() => setImageToShow(undefined)}
          cameraName={project.getCameraById(imageToShow.cameraId)?.name}
        />
      ) : null}
    </div>
  );
}
