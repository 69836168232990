import {create} from 'zustand';
import {Tag} from '@shared/types/Tag';
import {TimeRange} from '@shared/ui/TimeRange';
import {DateRange} from '@shared/ui/Date';
import {MilestoneRange} from '@shared/types/Search';
import {PresetKey} from '@shared/ui/Date';
import {SearchQuery, TagOperator} from '../types/searchQuery';
import {
  ProjectFilterStore,
  ProjectFilterStoreActions,
  getProjectFilterInitialState
} from '@entities/projectFilter';
import {devtools, persist} from 'zustand/middleware';
import {createSearchParamStorage, isFiltersSearchParamStorageEnabled} from '@shared-app/lib';
import {ViewType} from '@features/cameraListViewSwitch';

export interface SmartSearchFilterState extends ProjectFilterStore {
  query: SearchQuery;
  currentSavedSearchId?: string;
  lastSavedSearchId?: string;
  viewType: ViewType;
}

export interface SmartSearchFilterActions extends ProjectFilterStoreActions {
  toggleTagInQuery: (tag: Tag) => void;
  removeAllTagsInQuery: () => void;
  setQueryOperator: (operator: TagOperator) => void;
  setTagsInQuery: (update: (tags: Tag[]) => Tag[]) => void;
  reset: () => void;
  set: (s: SmartSearchFilterState) => void;
  setViewType: (v: ViewType) => void;
}

const getInitialState = (): SmartSearchFilterState => ({
  ...getProjectFilterInitialState(),
  query: {operator: TagOperator.And, tags: []},
  currentSavedSearchId: undefined,
  lastSavedSearchId: undefined,
  viewType: ViewType.IMAGES
});

const storageOptions = {
  name: 'filters',
  storage: createSearchParamStorage(isFiltersSearchParamStorageEnabled)
};

export const useSmartSearchFilter = create<SmartSearchFilterState & SmartSearchFilterActions>()(
  persist(
    devtools(
      set => ({
        ...getInitialState(),
        setCameras: (cameras: string[]) => {
          return set(state => ({
            cameras,
            ...resetCurrentSavedSearch(state)
          }));
        },
        setDateRange: (dateRange: DateRange, milestoneRange: MilestoneRange) => {
          return set(state => ({
            dateRange,
            milestoneRange,
            dateRangePreset: undefined,
            ...resetCurrentSavedSearch(state)
          }));
        },
        setDateRangePreset: (dateRangePreset?: PresetKey, dateRange?: DateRange) => {
          return set(state => ({
            dateRangePreset,
            dateRange: dateRange ? dateRange : state.dateRange, // incase of 'custom' preset, `dateRange` is `undefined`
            milestoneRange: {startMilestoneId: undefined, endMilestoneId: undefined},
            ...resetCurrentSavedSearch(state)
          }));
        },
        setTimeRange: (timeRange: TimeRange) => {
          return set(state => ({
            timeRange,
            ...resetCurrentSavedSearch(state)
          }));
        },
        setTagsInQuery: (update: (tags: Tag[]) => Tag[]) => {
          set(state => ({query: {operator: state.query.operator, tags: update(state.query.tags)}}));
        },
        setViewType: (viewType: ViewType) => {
          set(() => ({viewType}));
        },
        toggleTagInQuery: (tag: Tag) => {
          set(state => {
            const {operator, tags: currentTags} = state.query;
            const updatedTags = toggleTag(currentTags, tag);
            return {
              query: {operator, tags: updatedTags},
              ...resetCurrentSavedSearch(state)
            };
          });
        },
        removeAllTagsInQuery: () => {
          set(state => ({
            query: {operator: state.query.operator, tags: []}
          }));
        },
        setQueryOperator: (operator: TagOperator) => {
          set(state => {
            return {
              query: {operator: operator, tags: state.query.tags}
            };
          });
        },
        reset: () => {
          set(getInitialState());
        },
        set: (changes: SmartSearchFilterState) =>
          set(state => ({
            ...resetCurrentSavedSearch(state),
            ...changes
          }))
      }),
      {name: 'smart-search-filter'}
    ),
    storageOptions
  )
);

function resetCurrentSavedSearch(state: SmartSearchFilterState): {
  currentSavedSearchId?: string;
  lastSavedSearchId: string;
} {
  return {
    currentSavedSearchId: undefined,
    lastSavedSearchId: state.currentSavedSearchId || state.lastSavedSearchId
  };
}

export const isSelected = (tag: Tag) => {
  return useSmartSearchFilter(state => findTagIndex(state.query.tags, tag) !== -1);
};

export const areCameraIdsInitialized = () => {
  return useSmartSearchFilter(state => state.cameras !== undefined);
};

function findTagIndex(tags: Tag[], tag: Tag) {
  return tags.findIndex(tagInFilter => tagInFilter.key === tag.key);
}

function toggleTag(tags: Tag[], tag: Tag) {
  const selectedTagIndex = findTagIndex(tags, tag);
  return selectedTagIndex === -1
    ? [...tags, tag]
    : tags.filter((_, index) => selectedTagIndex !== index);
}
