import {useEffect, useRef} from 'react';
import {Typography} from '@shared/ui/Typography';
import {useTranslation} from 'react-i18next';
import {preventDefault} from '@shared-app/lib';
import styles from './HighLevelSummary.module.scss';

interface HighLevelSummaryProps {
  summary: string;
  onChange: (value: string) => void;
  editable?: boolean;
}

export function HighLevelSummary({summary, onChange, editable}: HighLevelSummaryProps) {
  const {t} = useTranslation('statusReport');
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleChange = (event: Event) => {
      onChange((event.target as HTMLDivElement).textContent);
    };

    const element = ref.current;
    element.addEventListener('input', handleChange);

    return () => {
      element.removeEventListener('input', handleChange);
    };
  }, [onChange]);

  return (
    <div className={styles.container}>
      <Typography classes={styles.title} variant="subtitle1">
        {t('High Level Summary')}
      </Typography>
      <div
        className={styles.content}
        ref={ref}
        onDrop={preventDefault}
        contentEditable={editable}
        suppressContentEditableWarning
      >
        {summary}
      </div>
    </div>
  );
}
