export function PrivacyTypeBoxBlur({opacity}: {opacity: number}) {
  return (
    <svg
      width="181"
      height="121"
      viewBox="0 0 181 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M157.706 98.2385H2.02344C1.47115 98.2385 1.02344 97.7908 1.02344 97.2385V51.8712V23.1641C1.02344 22.6118 1.47115 22.1641 2.02344 22.1641L26.6466 22.1641C27.7512 22.1641 28.6466 23.0595 28.6466 24.1641V50.8712C28.6466 51.4235 29.0944 51.8712 29.6466 51.8712H49.9566C50.5089 51.8712 50.9566 51.4235 50.9566 50.8712V43.9416C50.9566 42.837 51.8521 41.9416 52.9566 41.9416H71.2666C72.3712 41.9416 73.2666 41.0462 73.2666 39.9416V36.1431C73.2666 35.5908 73.7143 35.1431 74.2666 35.1431H87.7748C88.327 35.1431 88.7748 35.5908 88.7748 36.1431V46.5435C88.7748 47.0958 89.2225 47.5435 89.7748 47.5435H98.0184C98.5707 47.5435 99.0184 47.9912 99.0184 48.5435V71.6931"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <circle cx="41.9844" cy="14.0078" r="3.5" fill="#99CEFF" stroke="#4C4C4C" />
      <rect x="4.5" y="26.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="12.918" y="26.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="21.3379" y="26.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="4.5" y="34.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="12.918" y="34.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="21.3379" y="34.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="4.5" y="43.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="12.918" y="43.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="21.3379" y="43.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="53.5" y="44.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="60.918" y="44.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="68.3379" y="44.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="82.2227" y="36.9141" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <path
        d="M56.957 81.7339C36.7202 82.852 7.69172 82.9293 2.00915 82.9335C1.45634 82.9339 1.02344 83.3821 1.02344 83.9349V118.225C1.02344 118.777 1.47115 119.225 2.02344 119.225H177.784C178.336 119.225 178.784 118.777 178.784 118.225V70.7266C178.784 69.622 177.899 68.7266 176.794 68.7266L123.613 68.7266C96.8532 68.7266 79.479 80.4895 56.957 81.7339Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <rect x="75.459" y="36.9141" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <path
        d="M1.38086 48.8565L1.38088 1.625C1.38088 1.07271 1.8286 0.625 2.38088 0.625H177.785C178.337 0.625 178.785 1.07272 178.785 1.625V109.073"
        stroke="#4C4C4C"
        strokeLinejoin="round"
      />
      <path
        d="M146.925 85.4156L154.15 74.2845C154.554 73.6615 155.472 73.6811 155.85 74.3207L162.417 85.4518C162.811 86.1184 162.33 86.96 161.556 86.96H161.291C160.445 86.96 159.982 87.9447 160.52 88.5968L163.611 92.3383C164.15 92.9904 163.686 93.9751 162.84 93.9751H145.96C145.055 93.9751 144.615 92.8678 145.275 92.2471L149.055 88.688C149.714 88.0673 149.275 86.96 148.369 86.96H147.764C146.971 86.96 146.494 86.0808 146.925 85.4156Z"
        fill="#E5F3FF"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M120.334 96.9118L126.28 88.0348C126.686 87.428 127.585 87.4474 127.965 88.0712L133.422 97.0335C133.787 97.6333 133.355 98.4017 132.653 98.4017C131.885 98.4017 131.47 99.3014 131.968 99.8858L134.27 102.587C134.824 103.236 134.362 104.235 133.509 104.235H119.785C118.872 104.235 118.436 103.112 119.111 102.497L121.775 100.066C122.42 99.4769 122.004 98.4017 121.13 98.4017C120.364 98.4017 119.908 97.5478 120.334 96.9118Z"
        fill="#E5F3FF"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M136.168 42.6004V39.6787C136.168 39.513 136.302 39.3787 136.468 39.3787H139.118C139.284 39.3787 139.418 39.513 139.418 39.6787V42.6004C139.418 42.7661 139.284 42.9004 139.118 42.9004H136.468C136.302 42.9004 136.168 42.7661 136.168 42.6004Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <mask id="path-22-inside-1_12996_228944" fill="white">
        <path d="M132.834 47.9314C132.282 47.9314 131.825 48.3822 131.917 48.9268C132.054 49.7348 132.355 50.5042 132.804 51.1764C133.435 52.1204 134.324 52.8246 135.346 53.1886C136.367 53.5526 137.469 53.5579 138.494 53.2037C139.518 52.8494 140.413 52.1538 141.052 51.2159C141.69 50.2779 142.039 49.1456 142.048 47.9803C142.058 46.815 141.727 45.6762 141.105 44.7262C140.482 43.7761 139.598 43.0634 138.579 42.6896C137.863 42.4268 137.106 42.3409 136.362 42.4329C135.831 42.4985 135.547 43.0492 135.694 43.5633L136.012 44.6768C136.168 45.225 136.773 45.5055 137.332 45.6163C137.435 45.6367 137.537 45.6654 137.638 45.7022C138.071 45.8612 138.447 46.1643 138.712 46.5683C138.977 46.9723 139.117 47.4566 139.113 47.9522C139.109 48.4478 138.961 48.9293 138.689 49.3282C138.418 49.727 138.037 50.0229 137.601 50.1735C137.166 50.3242 136.697 50.3219 136.263 50.1671C135.828 50.0123 135.45 49.7128 135.182 49.3114C135.096 49.1836 135.024 49.0476 134.964 48.9056C134.75 48.3963 134.322 47.9314 133.769 47.9314H132.834Z" />
      </mask>
      <path
        d="M132.834 47.9314C132.282 47.9314 131.825 48.3822 131.917 48.9268C132.054 49.7348 132.355 50.5042 132.804 51.1764C133.435 52.1204 134.324 52.8246 135.346 53.1886C136.367 53.5526 137.469 53.5579 138.494 53.2037C139.518 52.8494 140.413 52.1538 141.052 51.2159C141.69 50.2779 142.039 49.1456 142.048 47.9803C142.058 46.815 141.727 45.6762 141.105 44.7262C140.482 43.7761 139.598 43.0634 138.579 42.6896C137.863 42.4268 137.106 42.3409 136.362 42.4329C135.831 42.4985 135.547 43.0492 135.694 43.5633L136.012 44.6768C136.168 45.225 136.773 45.5055 137.332 45.6163C137.435 45.6367 137.537 45.6654 137.638 45.7022C138.071 45.8612 138.447 46.1643 138.712 46.5683C138.977 46.9723 139.117 47.4566 139.113 47.9522C139.109 48.4478 138.961 48.9293 138.689 49.3282C138.418 49.727 138.037 50.0229 137.601 50.1735C137.166 50.3242 136.697 50.3219 136.263 50.1671C135.828 50.0123 135.45 49.7128 135.182 49.3114C135.096 49.1836 135.024 49.0476 134.964 48.9056C134.75 48.3963 134.322 47.9314 133.769 47.9314H132.834Z"
        fill="#99CEFF"
        stroke="#4C4C4C"
        strokeWidth="2"
        mask="url(#path-22-inside-1_12996_228944)"
      />
      <rect
        x="156.166"
        y="31.6614"
        width="5.5"
        height="29.5217"
        rx="0.5"
        fill="white"
        stroke="#4C4C4C"
      />
      <rect
        x="134.5"
        y="28.1396"
        width="6.58333"
        height="3.69565"
        rx="0.5"
        fill="#E5F3FF"
        stroke="#4C4C4C"
      />
      <rect
        x="136.666"
        y="31.6614"
        width="2.25"
        height="7.21739"
        rx="0.5"
        fill="white"
        stroke="#4C4C4C"
      />
      <rect
        x="164.834"
        y="29.3135"
        width="5.5"
        height="8.3913"
        rx="0.5"
        fill="#E5F3FF"
        stroke="#4C4C4C"
      />
      <path
        d="M146.416 64.975C146.416 64.7772 146.533 64.598 146.713 64.5179L151.883 62.226C151.947 62.1977 152.016 62.1831 152.086 62.1831H164.693C164.753 62.1831 164.812 62.1939 164.869 62.215L171.092 64.5492C171.287 64.6224 171.416 64.8089 171.416 65.0173V67.7266C171.416 68.0027 171.192 68.2266 170.916 68.2266H146.916C146.64 68.2266 146.416 68.0027 146.416 67.7266V64.975Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
      />
      <path
        d="M157.964 16.2196L133.435 24.4469C132.449 24.7774 132.56 26.2056 133.584 26.3807L133.819 26.4209C133.991 26.4502 134.167 26.4343 134.331 26.3747L158.441 17.5828C158.726 17.4789 158.916 17.2078 158.916 16.9043C158.916 16.4108 158.432 16.0627 157.964 16.2196Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M146.516 26.3673L157.304 20.7638C157.629 20.5952 157.833 20.2598 157.833 19.894C157.833 19.1513 157.039 18.6785 156.386 19.0323L145.697 24.8234C144.9 25.2553 145.207 26.4657 146.114 26.4657C146.254 26.4657 146.392 26.4319 146.516 26.3673Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M168.913 28.4682L160.177 18.2589C160.063 18.1258 160 17.9562 160 17.781C160 17.1117 160.822 16.7906 161.275 17.2824L170.388 27.1571C170.972 27.7893 170.523 28.8134 169.663 28.8134C169.374 28.8134 169.1 28.6873 168.913 28.4682Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M161.083 32.3352L157.705 35.0809C157.212 35.4811 157.212 36.2328 157.705 36.633L160.128 38.6026C160.621 39.0028 160.621 39.7545 160.128 40.1547L157.705 42.1244C157.212 42.5246 157.212 43.2763 157.705 43.6765L160.128 45.6461C160.621 46.0463 160.621 46.798 160.128 47.1982L157.705 49.1679C157.212 49.5681 157.212 50.3197 157.705 50.7199L160.128 52.6896C160.621 53.0898 160.621 53.8415 160.128 54.2417L157.705 56.2113C157.212 56.6115 157.212 57.3632 157.705 57.7634L161.083 60.5091"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M157.916 26.4656H126.404C126.096 26.4656 125.805 26.6074 125.616 26.8501L123.797 29.1796C123.54 29.5079 123.774 29.9873 124.191 29.9873H157.916C158.468 29.9873 158.916 29.5396 158.916 28.9873V27.4656C158.916 26.9133 158.468 26.4656 157.916 26.4656Z"
        fill="#99CEFF"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M161.86 22.8778L160.298 16.5577C160.202 16.1716 159.856 15.9004 159.458 15.9004C159.061 15.9004 158.714 16.1716 158.619 16.5577L157.057 22.8778C156.901 23.5083 157.378 24.1178 158.027 24.1178H160.889C161.539 24.1178 162.016 23.5083 161.86 22.8778Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M153.774 29.4875C153.327 29.4875 153.105 28.9452 153.423 28.6313L157.351 24.7617C157.445 24.6696 157.571 24.6179 157.702 24.6179H162.251C162.527 24.6179 162.751 24.8418 162.751 25.1179V28.9875C162.751 29.2636 162.527 29.4875 162.251 29.4875H153.774Z"
        fill="white"
        stroke="#4C4C4C"
      />
      <rect
        x="162.666"
        y="26.9656"
        width="9.83333"
        height="2.52174"
        rx="0.5"
        fill="#99CEFF"
        stroke="#4C4C4C"
      />
      <rect
        x="155.084"
        y="59.8352"
        width="7.66667"
        height="2.52174"
        rx="0.5"
        fill="#E5F3FF"
        stroke="#4C4C4C"
      />
      <rect
        x="155.084"
        y="29.3135"
        width="7.66667"
        height="2.52174"
        rx="0.5"
        fill="#E5F3FF"
        stroke="#4C4C4C"
      />
      <path
        d="M31.8785 80.2312H17.6738C17.1215 80.2312 16.6738 79.7835 16.6738 79.2312V76.9761C16.6738 76.6203 16.8628 76.2914 17.1702 76.1122L18.6302 75.2611C18.7831 75.172 18.9569 75.125 19.1338 75.125H31.8785C32.4308 75.125 32.8785 75.5727 32.8785 76.125V76.8271V79.2312C32.8785 79.7835 32.4308 80.2312 31.8785 80.2312Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M36.1912 74.5677H35.3378C34.713 74.5677 34.2411 74.001 34.3544 73.3865L36.9163 59.4828C37.0038 59.0084 37.4174 58.6641 37.8998 58.6641H40.2536C40.8059 58.6641 41.2536 59.1118 41.2536 59.6641V60.9545C41.2536 61.5068 40.8059 61.9545 40.2536 61.9545H39.6178C39.1164 61.9545 38.6925 62.3258 38.6265 62.8229L37.1825 73.6993C37.1165 74.1964 36.6926 74.5677 36.1912 74.5677Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M37.5724 78.1072H33.8789C33.3266 78.1072 32.8789 77.6595 32.8789 77.1072V75.7031C32.8789 75.1508 33.3266 74.7031 33.8789 74.7031H37.5724C38.1247 74.7031 38.5724 75.1508 38.5724 75.7031V77.1072C38.5724 77.6595 38.1247 78.1072 37.5724 78.1072Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M29.1562 79.7904V79.1138C29.1562 78.5615 29.604 78.1138 30.1563 78.1138H39.1199V65.1953C39.1199 64.643 39.5676 64.1953 40.1199 64.1953H44.5985C45.9417 64.1953 47.1213 65.0882 47.4859 66.3811L48.455 69.8177C48.5048 69.9943 48.5301 70.177 48.5301 70.3605V78.1138C48.8358 78.1138 49.0836 78.3616 49.0836 78.6673V79.7904C49.0836 80.3427 48.6359 80.7904 48.0836 80.7904H30.1562C29.604 80.7904 29.1562 80.3427 29.1562 79.7904Z"
        fill="white"
      />
      <path
        d="M39.1199 78.1138H30.1563C29.604 78.1138 29.1562 78.5615 29.1562 79.1138V79.7904C29.1562 80.3427 29.604 80.7904 30.1562 80.7904H48.0836C48.6359 80.7904 49.0836 80.3427 49.0836 79.7904V78.6673C49.0836 78.3616 48.8358 78.1138 48.5301 78.1138V78.1138M39.1199 78.1138V65.1953C39.1199 64.643 39.5676 64.1953 40.1199 64.1953H44.5985C45.9417 64.1953 47.1213 65.0882 47.4859 66.3811L48.455 69.8177C48.5048 69.9943 48.5301 70.177 48.5301 70.3605V78.1138M39.1199 78.1138H48.5301"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M41.2539 70.8013V66.5781C41.2539 66.0258 41.7016 65.5781 42.2539 65.5781H44.5073C45.4199 65.5781 46.2168 66.1959 46.4442 67.0797L47.3377 70.5521C47.5005 71.1849 47.0227 71.8013 46.3692 71.8013H42.2539C41.7016 71.8013 41.2539 71.3536 41.2539 70.8013Z"
        fill="#CCE6FF"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M33.2518 65.6332L19.3879 67.4018C19.0098 67.45 18.6218 67.2796 18.5251 66.9105C18.1646 65.535 18.796 63.218 20.3493 62.746C21.8569 62.288 24.801 59.7373 28.5956 59.2532C31.8742 58.835 33.7086 62.1968 34.0504 64.6642C34.1184 65.1556 33.7439 65.5704 33.2518 65.6332Z"
        fill="#99CEFF"
        stroke="#4C4C4C"
      />
      <path
        d="M17.8433 67.5997L29.8062 66.0736C30.2242 66.0203 30.5955 65.6933 30.4641 65.2923C30.1131 64.2215 28.4708 63.3348 26.9131 62.7505C25.7311 62.3072 23.3143 60.6252 20.3654 61.0013C17.9338 61.3115 17.2293 63.8345 16.7243 66.4152C16.5936 67.0835 17.1663 67.686 17.8433 67.5997Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
      />
      <ellipse cx="21.054" cy="80.2364" rx="3.06575" ry="2.97861" fill="white" stroke="#4C4C4C" />
      <ellipse cx="21.0541" cy="80.2375" rx="1.31389" ry="1.27655" fill="white" stroke="#4C4C4C" />
      <ellipse cx="28.0618" cy="80.2364" rx="3.06575" ry="2.97861" fill="white" stroke="#4C4C4C" />
      <ellipse cx="28.0619" cy="80.2375" rx="1.31389" ry="1.27655" fill="white" stroke="#4C4C4C" />
      <ellipse cx="43.8275" cy="80.2364" rx="3.06575" ry="2.97861" fill="white" stroke="#4C4C4C" />
      <ellipse cx="43.8276" cy="80.2375" rx="1.31389" ry="1.27655" fill="white" stroke="#4C4C4C" />
      <path
        d="M14.9219 72.4642V68.6093C14.9219 68.1099 15.2904 67.687 15.7852 67.6187L34.2137 65.0765C34.8819 64.9843 35.4479 65.5667 35.3366 66.232L34.0818 73.736C34.0012 74.218 33.5841 74.5711 33.0955 74.5711H16.9707C16.6961 74.5711 16.4337 74.4582 16.2448 74.2589L15.196 73.1521C15.02 72.9663 14.9219 72.7201 14.9219 72.4642Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M51.6711 94.5859C31.5076 94.9167 6.85283 97.1537 1.91007 97.6187C1.39771 97.6668 1.02344 98.0956 1.02344 98.6102V118.223C1.02344 118.775 1.47115 119.223 2.02344 119.223H177.784C178.336 119.223 178.784 118.775 178.784 118.223V106.232C178.784 105.127 177.896 104.244 176.795 104.332C170.5 104.834 155.452 107.055 127.263 107.055C100.503 107.055 74.1931 94.2165 51.6711 94.5859Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <rect
        x="78.6055"
        y="89.8125"
        width="4.87347"
        height="18.1637"
        fill="white"
        stroke="#4C4C4C"
      />
      <rect
        x="84.6699"
        y="89.8125"
        width="4.87347"
        height="18.1637"
        fill="white"
        stroke="#4C4C4C"
      />
      <rect
        x="77.8848"
        y="108.672"
        width="5.91679"
        height="2.17281"
        rx="0.5"
        fill="#99CEFF"
        stroke="#4C4C4C"
      />
      <rect
        x="84.7227"
        y="108.672"
        width="5.91679"
        height="2.17281"
        rx="0.5"
        fill="#99CEFF"
        stroke="#4C4C4C"
      />
      <path
        d="M72.4844 80.5516C72.4844 80.5516 75.3012 76.026 77.3763 72.1875L77.7337 72.4039V79.1413L75.7808 81.3452L75.3753 87.5761L73.3954 88.2332C72.9905 86.2988 72.4844 80.5516 72.4844 80.5516Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinejoin="round"
      />
      <path
        d="M96.123 80.8954C96.123 80.8954 93.3062 76.3697 91.2312 72.5312L90.8737 72.7476V79.485L92.8266 81.689L93.2321 87.9198L95.212 88.5769C95.6169 86.6426 96.123 80.8954 96.123 80.8954Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinejoin="round"
      />
      <path
        d="M86.663 89.3109H82.1426C81.5903 89.3109 81.1426 88.8632 81.1426 88.3109V72.8044C81.1426 72.2522 81.5903 71.8044 82.1426 71.8044H86.663C87.2153 71.8044 87.663 72.2522 87.663 72.8044V88.3109C87.663 88.8632 87.2153 89.3109 86.663 89.3109Z"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M84.4902 79.2734H84.1191C83.5669 79.2734 83.1191 79.7212 83.1191 80.2734V86.5997C83.1191 87.152 83.5669 87.5997 84.1191 87.5997H84.4902C85.0425 87.5997 85.4902 87.152 85.4902 86.5997V80.2734C85.4902 79.7212 85.0425 79.2734 84.4902 79.2734Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M90.8873 78.8338V72.636C90.8873 72.1607 90.5526 71.751 90.0868 71.6561L88.4827 71.3296C88.0112 71.2336 87.5381 71.4871 87.3569 71.9329L85.1688 77.315C85.1202 77.4346 85.0951 77.5625 85.0951 77.6916V87.3573C85.0951 87.4781 85.117 87.5979 85.1597 87.7108L85.9235 89.7319C86.0705 90.121 86.443 90.3785 86.8589 90.3785H90.4679C90.7362 90.3785 90.9932 90.2707 91.1812 90.0793L92.3168 88.9235C92.5006 88.7365 92.6035 88.4848 92.6035 88.2226V81.3987C92.6035 81.1365 92.5006 80.8848 92.3168 80.6978L91.174 79.5346C90.9903 79.3476 90.8873 79.096 90.8873 78.8338Z"
        fill="#99CEFF"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M85.0951 81.8359H92.6035V83.5439H85.0951V81.8359Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M85.0951 85.6797H92.6035V87.3876H85.0951V85.6797Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M87.8613 81.8387L87.8613 72.4999C87.8613 71.8325 88.5027 71.3524 89.143 71.5404L89.442 71.6282L89.442 81.8387L87.8613 81.8387Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M77.7221 78.8338V72.636C77.7221 72.1607 78.0567 71.751 78.5226 71.6561L80.1267 71.3296C80.5982 71.2336 81.0713 71.4871 81.2525 71.9329L83.4406 77.315C83.4892 77.4346 83.5142 77.5625 83.5142 77.6916V87.3573C83.5142 87.4781 83.4924 87.5979 83.4497 87.7108L82.6859 89.7319C82.5389 90.121 82.1664 90.3785 81.7505 90.3785H78.1415C77.8732 90.3785 77.6161 90.2707 77.4281 90.0793L76.2925 88.9235C76.1088 88.7365 76.0059 88.4848 76.0059 88.2226V81.3987C76.0059 81.1365 76.1088 80.8848 76.2925 80.6978L77.4354 79.5346C77.6191 79.3476 77.7221 79.096 77.7221 78.8338Z"
        fill="#99CEFF"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M83.5142 81.8359H76.0059V83.5439H83.5142V81.8359Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M83.5142 85.6797H76.0059V87.3876H83.5142V85.6797Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M80.748 81.8387L80.748 72.4999C80.748 71.8325 80.1067 71.3524 79.4663 71.5404L79.1673 71.6282L79.1673 81.8387L80.748 81.8387Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M81.1797 71.2949V70.8685C81.1797 70.7642 81.2642 70.6797 81.3685 70.6797H87.6908C87.7951 70.6797 87.8796 70.7642 87.8796 70.8685V71.2949C87.8796 73.1451 86.3798 74.6449 84.5297 74.6449C82.6795 74.6449 81.1797 73.1451 81.1797 71.2949Z"
        fill="#E5F3FF"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M82.3984 71.3844V68.1213C82.3984 67.9723 82.5192 67.8516 82.6682 67.8516H86.3923C86.5413 67.8516 86.662 67.9723 86.662 68.1213V71.3844C86.662 72.5617 85.7076 73.5162 84.5302 73.5162C83.3529 73.5162 82.3984 72.5617 82.3984 71.3844Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M79.3516 64.3707V59.695C79.3516 59.65 79.3822 59.6106 79.4259 59.5995L84.0731 58.4217C84.5975 58.2888 85.1479 58.2981 85.6675 58.4486L89.6349 59.5978C89.6771 59.61 89.706 59.6486 89.706 59.6924V64.3707C89.706 67.23 87.3881 69.5479 84.5288 69.5479C81.6695 69.5479 79.3516 67.23 79.3516 64.3707Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M90.2791 63.3203H89.7949C89.7469 63.3203 89.7079 63.3593 89.7079 63.4073V65.492C89.7079 66.0892 90.4589 66.3543 90.8338 65.8894L91.2568 65.3648C91.4369 65.1415 91.5352 64.8632 91.5352 64.5763C91.5352 63.8827 90.9728 63.3203 90.2791 63.3203Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M78.7814 63.3203H79.2656C79.3137 63.3203 79.3527 63.3593 79.3527 63.4073V65.492C79.3527 66.0892 78.6017 66.3543 78.2268 65.8894L77.8037 65.3648C77.6236 65.1415 77.5254 64.8632 77.5254 64.5763C77.5254 63.8827 78.0877 63.3203 78.7814 63.3203Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M84.6888 59.7931C84.1388 59.8426 83.6141 59.8195 83.1991 59.7046C82.6824 59.5615 82.0405 59.366 81.6038 59.6771C80.4162 60.5235 79.8207 61.6491 79.4534 62.5634C79.1664 63.2777 78.586 63.2155 78.4408 62.4595C78.275 61.5962 78.1348 60.6926 78.1348 60.0855C78.1348 59.28 78.5827 58.2556 79.658 58.0132C80.2447 57.881 80.8139 57.4907 81.0809 56.9519C82.2185 54.6561 86.0897 54.1746 88.693 55.7032C91.7347 57.4891 91.0108 61.6848 90.2397 63.6601C90.0074 64.2552 89.4891 64.1372 89.3365 63.5168L88.9514 61.9504C88.7024 60.938 85.7272 59.6997 84.6888 59.7931Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M81.9312 52.2041C78.5244 52.9835 77.6756 56.502 77.8055 58.3048V58.4585L84.6854 58.9229C87.7237 58.9229 88.5557 58.3048 91.6547 58.3048C90.9564 54.5778 89.8994 53.0504 87.138 52.2041C87.174 52.0023 86.7624 51.5859 86.7624 51.5859H82.2037C82.2037 51.5859 81.9312 51.9131 81.9312 52.2041Z"
        fill="#E5F3FF"
        stroke="#4C4C4C"
      />
      <path
        d="M77.7094 57.3804C77.7094 57.3804 77.0985 57.7933 76.4861 58.3328C75.8737 58.8723 76.3534 59.0042 76.5589 59.1086C76.7644 59.213 77.7094 59.2852 77.7094 59.2852C77.7094 59.2852 81.7617 60.3205 84.3877 60.3434C87.1661 60.3675 91.5795 59.2852 91.5795 59.2852C91.5795 59.2852 92.9606 59.3598 92.9606 59.1086C92.9606 58.8066 92.65 58.4553 92.65 58.4553L91.4589 57.3804C91.4589 57.3804 87.1629 58.3546 84.3877 58.3328C81.7651 58.3122 77.7094 57.3804 77.7094 57.3804Z"
        fill="#99CEFF"
        stroke="#4C4C4C"
      />
      <path d="M82.0254 52.2188L82.9932 57.9071H85.6735L86.9391 52.2188" stroke="#4C4C4C" />
      <path
        d="M75.9508 89.4002C76.2603 89.915 76.3941 90.4418 76.3823 90.8628C76.3703 91.2905 76.2173 91.5369 76.0394 91.6439C75.8615 91.7509 75.5721 91.7706 75.1887 91.5807C74.8113 91.3937 74.4086 91.0286 74.0991 90.5139C73.7895 89.9991 73.6557 89.4723 73.6675 89.0513C73.6795 88.6236 73.8325 88.3772 74.0105 88.2702C74.1884 88.1632 74.4777 88.1435 74.8611 88.3334C75.2386 88.5204 75.6412 88.8855 75.9508 89.4002Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
      />
      <path
        d="M93.1 89.4002C92.7905 89.915 92.6566 90.4418 92.6685 90.8628C92.6804 91.2905 92.8335 91.5369 93.0114 91.6439C93.1893 91.7509 93.4786 91.7706 93.862 91.5807C94.2395 91.3937 94.6421 91.0286 94.9517 90.5139C95.2613 89.9991 95.3951 89.4723 95.3833 89.0513C95.3713 88.6236 95.2183 88.3772 95.0403 88.2702C94.8624 88.1632 94.5731 88.1435 94.1897 88.3334C93.8122 88.5204 93.4096 88.8855 93.1 89.4002Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
      />
      <g filter="url(#filter0_b_12996_228944)">
        <rect
          x="71.3223"
          y="50.6016"
          width="26.2255"
          height="61.2405"
          transform="rotate(0.174685 71.3223 50.6016)"
          fill="#D9D9D9"
          fillOpacity={opacity}
          style={{filter: 'blur(0.1rem)'}}
        />
      </g>
      <defs>
        <filter
          id="filter0_b_12996_228944"
          x="67.1348"
          y="46.6016"
          width="34.4121"
          height="69.3201"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_12996_228944"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_12996_228944"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
