import styles from './ProjectPage.module.scss';
import {useRef, useState} from 'react';
import {ProjectImages} from '@widgets/project/projectImagesGrid';
import {SmartImageWithTagsModal} from '@features/smartImage/smartImageWithTagsModal';
import {SmartImage} from '@shared/types/SmartImage';
import {ProjectSearchExpandable} from '@widgets/project/projectSearch';
import {useCurrentProject} from '@entities/project';
import {useLocation, useNavigate} from 'react-router-dom';
import {ROUTES} from '@shared/lib/constants';
import {CameraTimeline} from '@widgets/project/cameraTimeline';

export default function ProjectPage() {
  const {project} = useCurrentProject();
  const containerRef = useRef<HTMLDivElement>();
  const [currentDate, setCurrentDate] = useState<Date>();
  const location = useLocation();
  const navigate = useNavigate();

  const isTimelineView = ROUTES.PROJECT.CAMERAS.TIMELINE.isMatch(location.pathname);

  const [activeImage, setActiveImage] = useState<SmartImage>();

  return (
    <div className={styles.container} ref={containerRef}>
      {isTimelineView ? (
        <>
          <ProjectSearchExpandable project={project} alwaysSticky onBack={() => navigate(-1)} />
          <CameraTimeline />
        </>
      ) : (
        <>
          <ProjectSearchExpandable
            project={project}
            currentDate={currentDate}
            onScrollToTop={() => containerRef.current.scroll({top: 0, behavior: 'smooth'})}
          />
          <ProjectImages
            setActiveImage={setActiveImage}
            project={project}
            onDateChange={setCurrentDate}
          />
        </>
      )}
      {activeImage ? (
        <SmartImageWithTagsModal
          image={activeImage}
          onClose={() => setActiveImage(undefined)}
          cameraName={project.getCameraById(activeImage.cameraId)?.name}
        />
      ) : null}
    </div>
  );
}
