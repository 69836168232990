import {Expose, Transform} from 'class-transformer';
import {ZonePolygon, ZoneSettings} from '@shared-app/types';

export class ZoneSettingsModel implements ZoneSettings {
  @Expose({name: 'exclusion_zones'})
  @Transform(({value}) => value || [], {toClassOnly: true})
  public exclusion: ZonePolygon[];

  @Expose({name: 'ppe_zones'})
  @Transform(({value}) => value || [], {toClassOnly: true})
  public ppe: ZonePolygon[];
}
