import {Typography} from '@shared/ui/Typography';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS} from '@shared/lib/constants';
import {useTranslation} from 'react-i18next';
import {BorderButton, Button} from '@shared/ui/Button';
import styles from './DetailsHeader.module.scss';
import {CircularLoader} from '@shared/ui/Loader';
import {GeneratePDF} from '@features/statusReport/generatePDF';
import {StatusReportDetails} from '@shared-app/types';

interface DetailHeaderProps {
  startTime: Date;
  timezone: string;
  onEdit: () => void;
  isEditing: boolean;
  onEditCancel: () => void;
  onSave: () => void;
  isSaving: boolean;
  reportId: string;
  report: StatusReportDetails;
}

export function DetailsHeader({
  startTime,
  timezone,
  onEdit,
  isEditing,
  onEditCancel,
  onSave,
  isSaving,
  reportId,
  report
}: DetailHeaderProps) {
  const {t} = useTranslation('statusReport');
  return (
    <div className={styles.header}>
      <div className={styles.titleAndDate}>
        <Typography variant="h4">{t('Daily Report')}</Typography>
        <span className={styles.graybg}>
          {formatInTimeZone(startTime, timezone, DATE_FORMATS.DATE)}
        </span>
      </div>
      <div className={styles.actions}>
        {isEditing ? (
          <>
            <Button size="small" onClick={onEditCancel} disabled={isSaving}>
              {t('Cancel')}
            </Button>
            <Button
              size="small"
              onClick={onSave}
              variant="contained"
              disabled={isSaving}
              startIcon={isSaving ? <CircularLoader size="1em" color="inherit" /> : undefined}
            >
              {t('Save Changes')}
            </Button>
          </>
        ) : (
          <BorderButton size="small" onClick={onEdit}>
            {t('Edit', {ns: 'translation'})}
          </BorderButton>
        )}

        <GeneratePDF
          reportId={reportId}
          isEditing={isEditing}
          timezone={timezone}
          report={report}
        />
      </div>
    </div>
  );
}
