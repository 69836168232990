import {useTranslation} from 'react-i18next';
import {useAnchorElement} from '@conxai/react-kit';
import {Box, FormControl, Menu, Typography, MenuItem} from '@mui/material';
import {LANGUAGES} from '../constants/languages';
import {LanguageOutlined} from '@shared-app/ui/icons';
import {useEffect} from 'react';
import {LanguageCode} from '../types/Language';
import styles from './LanguageSwitcher.module.scss';

export function LanguageSwitcher() {
  const {i18n} = useTranslation();

  const currentLanguage = i18n.language as LanguageCode;

  const {anchor, clearAnchor, setAnchor} = useAnchorElement();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    clearAnchor();
  };

  useEffect(() => {
    const defaultLanguage = LANGUAGES.find(({code}) => code === currentLanguage)
      ? currentLanguage
      : LanguageCode.EN;
    i18n.changeLanguage(defaultLanguage);
  }, [currentLanguage, i18n]);

  return (
    <Box className={styles.container}>
      <Box className={styles.iconContainer} onClick={setAnchor}>
        <LanguageOutlined className={styles.icon} />
        <Typography className={styles.label}>{currentLanguage}</Typography>
      </Box>
      <FormControl>
        <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={clearAnchor}>
          {LANGUAGES.map(({code, label}) => {
            return (
              <MenuItem key={code} className={styles.item} onClick={() => changeLanguage(code)}>
                {label}
              </MenuItem>
            );
          })}
        </Menu>
      </FormControl>
    </Box>
  );
}
