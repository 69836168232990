import {useTranslation} from 'react-i18next';
import {useMutation} from '@shared/lib/hooks';
import {useCurrentProject} from '@entities/project';
import {StatusReportDetails} from '@shared-app/types';
import {StatusReportService} from '../services/StatusReport.service';
import {makeReportDetailsQueryKey} from './utils/makeReportDetailsQueryKey';

export function useSaveStatusReport(reportId: string) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('statusReport');
  const listQueryOptions = {queryKey: makeReportDetailsQueryKey(projectId, reportId)};

  const {
    mutate: saveReport,
    isLoading: isSaving,
    isSuccess: isSaved
  } = useMutation<StatusReportDetails>({
    mutationFn: report => {
      return StatusReportService.saveStatusReport(projectId, reportId, report).catch(err => {
        console.error(err);
        throw new Error(t('Error occurred. Please try to save again.'));
      });
    },
    options: listQueryOptions
  });

  return {
    saveReport,
    isSaving,
    isSaved
  };
}
