import {reportAPI} from '@shared/api';
import {setZonedEndOfDay, setZonedStartOfDay} from '@shared/lib/utils';
import {instanceToPlain, plainToInstance} from 'class-transformer';
import {Finding, StatusReportDetails} from '@shared-app/types';
import {StatusReportModel} from '../model/StatusReport.model';
import {StatusReportDetailsModel} from '../model/StatusReportDetails.model';

export class StatusReportService {
  public static async getReports(projectId: string) {
    const reports = (await reportAPI.get(`projects/${projectId}/reports`).json()) as unknown[];

    return plainToInstance(StatusReportModel, reports);
  }

  public static async getReportDetails(projectId: string, reportId: string) {
    const report = (await reportAPI.get(`projects/${projectId}/reports/${reportId}`).json()) as {
      report: unknown;
    };
    return plainToInstance(StatusReportDetailsModel, report.report);
  }

  public static async generateReport(
    projectId: string,
    {
      cameraId,
      date,
      name,
      language,
      timezone
    }: {cameraId: string; date: Date; name: string; language: string; timezone: string}
  ) {
    const report = (await reportAPI
      .post(`projects/${projectId}/reports`, {
        json: {
          cameras: [cameraId],
          time_range: [
            {from: setZonedStartOfDay(date, timezone), to: setZonedEndOfDay(date, timezone)}
          ],
          name,
          language
        }
      })
      .json()) as unknown;

    return plainToInstance(StatusReportModel, report);
  }

  public static async deleteStatusReport(projectId: string, reportId: string) {
    return await reportAPI.delete(`projects/${projectId}/reports/${reportId}`).json();
  }

  public static async saveStatusReport(
    projectId: string,
    reportId: string,
    report: StatusReportDetails
  ) {
    await reportAPI.put(`projects/${projectId}/reports/${reportId}`, {
      json: getReportRequestBody(report)
    });
  }
}

const filterOutEmptyFindings = (findings: Finding[]) =>
  findings.filter(finding => finding.message.trim() !== '');

function getReportRequestBody(report: StatusReportDetails) {
  report.labourv2 = filterOutEmptyFindings(report.labourv2);
  report.ongoingActivitiesv2 = filterOutEmptyFindings(report.ongoingActivitiesv2);
  report.siteConditionsv2 = filterOutEmptyFindings(report.siteConditionsv2);
  report.equipmentv2 = filterOutEmptyFindings(report.equipmentv2);
  const requestBody = instanceToPlain(report);
  requestBody.report_date = {
    start: report.startTime,
    end: report.endTime
  };

  return requestBody;
}
