import Skeleton from '@shared/ui/Skeleton';
import styles from './ZoneConfigurationSkeleton.module.scss';

export function ZoneConfigurationSkeleton() {
  return (
    <div className={styles.container}>
      <Skeleton />
      <Skeleton />
    </div>
  );
}
