import React from 'react';
import styles from './GeneratePDF.module.scss';
import {useTranslation} from 'react-i18next';
import {WarningIcon} from '@shared/ui/icons';
import {Finding} from '@shared-app/types';

interface PDFSectionProps {
  title: string;
  items: Finding[];
}

export function PDFSection({title, items}: PDFSectionProps) {
  const {t} = useTranslation();

  if (items.length === 0) {
    return null;
  }

  const generalItems = items.filter(item => item.indicator === null);
  const observationItems = items.filter(item => item.indicator === 'warning');

  return (
    <div className={styles.section} style={{pageBreakInside: 'avoid'}}>
      <div className={styles.sectionTitle}>{title}</div>
      <div className={styles.sectionContent}>
        {generalItems.length > 0 && (
          <div>
            <ul>
              {generalItems.map((item, index) => (
                <li key={index} className={styles.sectionItem}>
                  {item.message}
                </li>
              ))}
            </ul>
          </div>
        )}

        {observationItems.length > 0 && (
          <div style={{pageBreakInside: 'avoid'}}>
            <div className={styles.sectionSubTitle}>
              <WarningIcon className={styles.warningIcon} />
              {observationItems.length} {t('Observations', {ns: 'statusReport'})}
            </div>

            <ul>
              {observationItems.map((item, index) => (
                <li key={index} className={styles.sectionObservationItem}>
                  {item.message}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
