import styles from './TimelineChart.module.scss';
import classNames from 'classnames';
import {ChartTooltip} from '@shared/ui/TimelineChart/types/ChartTooltip';
import React, {CSSProperties, Fragment, useMemo} from 'react';

export interface TimelineSVGProps {
  width: number;
  tooltipCallback: (cartTooltip?: ChartTooltip) => void;
  series: [Date, number][];
  hideEmpty?: boolean;
  onSelect?: (t: Date) => void;
  selectedTime: Date;
  options: {
    xaxis: {
      labels: {
        formatter: (timestamp: Date) => string;
      };
    };
    tooltip: {
      formatter: (seriesItem: [Date, number]) => React.ReactNode;
    };
  };
}

const BAR_HEIGHT = 30;

function TimelineSVG({
  width,
  tooltipCallback,
  series,
  onSelect,
  selectedTime,
  hideEmpty = true,
  options
}: TimelineSVGProps) {
  const config = {
    width,
    content: {
      height: BAR_HEIGHT + 10
    },
    footer: {
      height: 10
    },
    header: {
      height: 10
    },
    baseline: {
      width: 12
    },
    item: {
      height: '80%'
    }
  };

  const {
    tooltip: {formatter},
    xaxis: xAxisOptions
  } = options;
  const {
    labels: {formatter: xAxisLabelFormatter}
  } = xAxisOptions;

  const barSizeMultiplier = useMemo(
    () => config.width / series.reduce((acc, [, seriesValue]) => acc + Math.abs(seriesValue), 0),
    [series, config.width]
  );

  const content = {
    y: {
      from: config.header.height,
      to: config.header.height + config.content.height
    }
  };

  const yTop = content.y.from + 10;
  const yBottom = content.y.from + BAR_HEIGHT;

  let nextBarStartPosition = 0;

  return (
    <svg
      viewBox={`0 0 ${config.width} ${
        config.header.height + config.content.height + config.footer.height
      }`}
    >
      {series.map((seriesItem, index, allItems) => {
        const {xStart, xEnd} = getBarStartEndPositions(seriesItem, index);

        const [time, value] = seriesItem;
        const previousSeriesItem = allItems[index - 1];

        const shouldAddSeparator =
          previousSeriesItem &&
          xAxisLabelFormatter(time) !== xAxisLabelFormatter(previousSeriesItem[0]);

        const hasValue = value > 0;

        return (
          <Fragment key={`timeline-chart-item-${index}`}>
            <path
              onMouseEnter={() =>
                tooltipCallback({
                  title: formatter(seriesItem),
                  x: xStart,
                  y: -(yTop + yBottom),
                  width: xEnd - xStart,
                  height: yBottom - yTop
                })
              }
              onMouseLeave={() => {
                tooltipCallback();
              }}
              onClick={onSelect && hasValue ? () => onSelect(time) : undefined}
              className={classNames(styles.item, {
                [styles.item__absent]: hideEmpty && !hasValue,
                [styles.item__empty]: !hasValue
              })}
              d={`
              M ${xStart + (shouldAddSeparator ? 1 : 0)},${yTop}
              L ${xEnd + 0.1},${yTop}
              L ${xEnd + 0.1},${yBottom}
              L ${xStart + (shouldAddSeparator ? 1 : 0)},${yBottom}
              Z
            `}
            />
            {shouldAddSeparator && renderSeparator(xStart)}
            {shouldRenderSelector(time, selectedTime) &&
              renderSelector({xStart, xEnd, yTop, yBottom})}
          </Fragment>
        );
      })}
      {series.at(0) && renderXAxisLabel({label: xAxisLabelFormatter(series.at(0)[0]), x: 0})}
      {series.at(-1) &&
        renderXAxisLabel({
          label: xAxisLabelFormatter(series.at(-1)[0]),
          x: config.width,
          style: {textAnchor: 'end'}
        })}
    </svg>
  );

  function renderXAxisLabel({label, x, style}: {label: string; x: number; style?: CSSProperties}) {
    return (
      <text
        style={style}
        x={x}
        y={config.header.height + config.content.height + config.footer.height / 2}
        className={classNames(styles.footerText__text, styles.footerText__text_right)}
      >
        {label}
      </text>
    );
  }

  function renderSelector({
    xStart,
    xEnd,
    yTop,
    yBottom
  }: {
    xStart: number;
    xEnd: number;
    yTop: number;
    yBottom: number;
  }) {
    const selectorXStart = xStart + (xEnd - xStart) / 2;
    const selectorYTop = yTop - 3;
    const selectorYBottom = yBottom + 3;
    return (
      <line
        className={styles.selected}
        x1={selectorXStart}
        x2={selectorXStart}
        y1={selectorYTop}
        y2={selectorYBottom}
      />
    );
  }

  function shouldRenderSelector(timeToCheck: Date, selectedTime?: Date) {
    if (!selectedTime) return false;
    const barStartTimestamp = timeToCheck.getTime();
    const barEndTimestamp = timeToCheck.getTime() + 1 * 60 * 60 * 1000; // assumes chart is plotted with 1 hour interval
    const selectedTimestamp = selectedTime.getTime();

    return selectedTimestamp >= barStartTimestamp && selectedTimestamp < barEndTimestamp;
  }

  function renderSeparator(xStart: number) {
    const separatorYTop = yTop;
    const sepratorYBottom = yBottom;
    const separatorXEnd = xStart + 1;
    return (
      <path
        className={styles.separator}
        d={`
            M ${xStart},${separatorYTop}
            L ${separatorXEnd},${separatorYTop}
            L ${separatorXEnd},${sepratorYBottom}
            L ${xStart},${sepratorYBottom}
            Z
          `}
      />
    );
  }

  function getBarStartEndPositions(seriesItem: [Date, number], itemIndex: number) {
    if (itemIndex == 0) {
      nextBarStartPosition = 0;
    }
    const [, value] = seriesItem;

    const currentBarStart = nextBarStartPosition;
    nextBarStartPosition += barSizeMultiplier * Math.abs(value);

    const xStart = currentBarStart;
    const xEnd = nextBarStartPosition;

    return {xStart, xEnd};
  }
}

export default React.memo(TimelineSVG);
