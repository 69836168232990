import {PrivacySettings, PrivacyType} from '@shared-app/types';
import {Expose, Transform} from 'class-transformer';

export class PrivacySettingsModel implements PrivacySettings {
  @Expose({name: 'maskShape'})
  @Transform(({obj}) => getPrivacyType(obj))
  privacyType: PrivacyType;

  @Expose({name: 'opacity'})
  opacity: number;

  @Expose({name: 'color'})
  @Transform(({value}) => value || '#0000FF')
  color: string;
}

function getPrivacyType(obj: {
  maskShape: unknown;
  maskType: unknown;
  opacity: number;
}): PrivacyType {
  if (obj.opacity === 0) {
    return undefined;
  }
  if (obj.maskShape === 'box' && obj.maskType === 'fill') {
    return PrivacyType.BOX_FILL;
  } else if (obj.maskShape === 'contour' && obj.maskType === 'fill') {
    return PrivacyType.CONTOUR_FILL;
  } else if (obj.maskShape === 'box' && obj.maskType === 'blur') {
    return PrivacyType.BOX_BLUR;
  } else if (obj.maskShape === 'contour' && obj.maskType === 'blur') {
    return PrivacyType.CONTOUR_BLUR;
  }
}
