import {Divider, SelectChangeEvent} from '@mui/material';
import {MenuItem} from '@shared/ui/Menu';
import {DateRange, PresetKey} from '../../types';
import {useMemo} from 'react';
import {getPresetLabels} from '../../utils';
import {useTranslation} from 'react-i18next';
import styles from './DatePresetSelect.module.scss';
import {getEnumFromStringValue} from '@conxai/react-kit';
import {Select, SelectProps} from '../../../../../Select';
import classNames from 'classnames';
import {PRESET_FACTORY_MAP} from '../../defaults';
import {zonedTimeToUtc} from 'date-fns-tz';
import {isAfter} from 'date-fns';

interface Props {
  onChange: (presetKey?: PresetKey, dateRange?: DateRange) => void;
  value?: PresetKey;
  variant?: SelectProps['variant'];
  className: string;
  timezone: string;
}

export function DatePresetSelect(props: Props) {
  const {onChange, value, variant, className, timezone} = props;
  const {t} = useTranslation();

  const handleChange = (event: SelectChangeEvent<PresetKey>) => {
    const selectedPresetKeyStr = event.target.value;
    const selectedPresetKey = getEnumFromStringValue(PresetKey, selectedPresetKeyStr);
    if (selectedPresetKey) {
      const now = new Date();
      const {startDate, endDate} = PRESET_FACTORY_MAP[selectedPresetKey](now);
      const endDateValidated = isAfter(endDate, now) ? now : endDate;
      onChange(selectedPresetKey, {
        startDate: zonedTimeToUtc(startDate, timezone),
        endDate: zonedTimeToUtc(endDateValidated, timezone)
      });
    } else {
      onChange(undefined, undefined);
    }
  };

  const presetLabels = useMemo(() => getPresetLabels(t), [t]);

  return (
    <Select
      className={classNames(styles.root, className)}
      onChange={handleChange}
      displayEmpty
      value={value || ''}
      variant={variant}
      fullWidth
    >
      {DEFAULT_PRESETS.map(presetKey => (
        <MenuItem key={presetKey} value={presetKey}>
          {presetLabels[presetKey]}
        </MenuItem>
      ))}
      <Divider />
      <MenuItem value="">{t('Custom Date')}</MenuItem>
    </Select>
  );
}

const DEFAULT_PRESETS = [
  PresetKey.TODAY,
  PresetKey.YESTERDAY,
  PresetKey.THIS_WEEK,
  PresetKey.LAST_WEEK,
  PresetKey.LAST_TWO_WEEKS,
  PresetKey.THIS_MONTH,
  PresetKey.LAST_MONTH
];
