export const ZONE_COLORS = {
  exclusion: {
    fill: 'rgba(0, 53, 102, 0.55)',
    stroke: 'rgba(24, 144, 255, 1)',
    border: 'rgba(0, 79, 153, 1)'
  },
  ppe: {
    fill: 'rgba(255, 152, 0, 0.55)',
    stroke: 'rgba(230, 81, 0, 1)',
    border: 'rgba(239, 108, 0, 1)'
  }
};
