import {Outlet, useParams} from 'react-router-dom';
import Header from './Header/Header';
import styles from './ProjectLayout.module.scss';
import SideNav from '@widgets/project/sideNav';
import {useCurrentProject, useGetProject} from '@entities/project';
import {LoadingPagePlaceholder} from '@shared/ui/LoadingPagePlaceholder';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {SmartSearchFilter} from '@features/smartSearchFilter';
import {useGetFeatureFlags} from '@entities/featureFlag';
import {FeatureFlagKey} from '@shared-app/types/FeatureFlag';

export default function ProjectLayout() {
  const {projectId: projectIdFromUrl} = useParams();
  const {t} = useTranslation();
  const {project: projectContext, setCurrentProject} = useCurrentProject();
  const {project, isFetching, isError} = useGetProject(projectIdFromUrl);
  const {featureFlags, isLoading: isFetchingFeatureFlags} = useGetFeatureFlags(project?.id);

  useEffect(() => {
    if (project) setCurrentProject(project);
  }, [project, setCurrentProject]);

  if (isError) {
    return <div>{t('Something went wrong')}</div>;
  }

  // wait for `projectContext` to be populated from above useEffect
  if (isFetching || !projectContext || isFetchingFeatureFlags) {
    return <LoadingPagePlaceholder />;
  }

  const featureFlagMap = featureFlags.reduce(
    (acc, {key, enabled}) => ({...acc, [key]: enabled}),
    {} as Record<FeatureFlagKey, boolean>
  );

  return (
    <div className={styles.container}>
      <Header project={project} />
      <div className={styles.columns}>
        <div className={styles.sidenav}>
          <SideNav featureFlagMap={featureFlagMap} />
        </div>
        <SmartSearchFilter featureFlagMap={featureFlagMap} />
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
