import {MenuItem} from '@shared/ui/Menu';
import {Select, SelectChangeEvent, SelectProps} from '@shared/ui/Select';
import {LANGUAGES} from '@entities/LanguageSwitcher';
import {LanguageCode} from '@entities/LanguageSwitcher';
import {getEnumFromStringValue} from '@conxai/react-kit';

export interface LanguageSelectProps extends Omit<SelectProps, 'onChange'> {
  onChange?: (v: LanguageCode) => void;
  timezone?: string;
  variant?: 'filled' | 'outlined' | 'standard' | 'rounded';
  value: LanguageCode;
}

export function LanguageSelect({onChange, variant, value, ...restProps}: LanguageSelectProps) {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = getEnumFromStringValue(LanguageCode, event.target.value);
    if (value) {
      onChange(value);
    }
  };

  return (
    <Select
      onChange={handleChange}
      variant={variant}
      value={value}
      {...restProps}
      displayEmpty
      fullWidth
    >
      {LANGUAGES.map(({code, label}) => {
        return (
          <MenuItem key={code} value={code}>
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
}
