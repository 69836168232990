import classNames from 'classnames';
import styles from './PrivacySettingsPage.module.scss';
import {PrivacyType} from '@shared-app/types';
import {Typography} from '@shared/ui/Typography';

interface PrivacyTypeOptionProps {
  privacyType?: PrivacyType;
  selected?: PrivacyType;
  children: React.ReactNode;
  label: string;
  onClick: (privacyType: PrivacyType) => void;
}

export function PrivacyTypeOption({
  privacyType,
  label,
  children,
  onClick,
  selected
}: PrivacyTypeOptionProps) {
  return (
    <div
      className={classNames(styles.option, {
        [styles.option__selected]: privacyType === selected
      })}
      onClick={() => onClick(privacyType)}
    >
      <Typography classes={styles.label} variant="subtitle2">
        {label}
      </Typography>
      {children}
    </div>
  );
}
