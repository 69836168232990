import React, {useRef} from 'react';
import styles from './GeneratePDF.module.scss';
import {Button} from '@shared/ui/Button';
import html2pdf from 'html2pdf.js';
import {StatusReportDetails} from '@shared-app/types';
import {useCurrentProject} from '@entities/project';
import {Trans, useTranslation} from 'react-i18next';
import {ConxaiGrayscale} from '@shared-app/ui/icons';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS} from '@shared/lib/constants';
import {PDFSection} from './PDFSection';
import {useConfetti} from '@shared-app/lib';

interface GeneratePDFProps {
  reportId: string;
  isEditing: boolean;
  timezone: string;
  report: StatusReportDetails;
}

export function GeneratePDF({reportId, timezone, isEditing, report}: GeneratePDFProps) {
  const triggerConfetti = useConfetti();
  const {t} = useTranslation('statusReport');
  const pdfRef = useRef<HTMLDivElement | null>(null);
  const {project} = useCurrentProject();

  console.log({report, project});

  return (
    <>
      <Button
        size={`small`}
        variant={`contained`}
        disabled={isEditing}
        onClick={() => {
          triggerConfetti();
          handleDownloadPdf(pdfRef, report.startTime, timezone);
        }}
      >
        {t('Download PDF')}
      </Button>

      <div className={styles.pdfContainer} ref={pdfRef}>
        <div className={styles.reportContainer}>
          <section className={styles.poweredByConxai}>
            {t('Powered by')}
            <ConxaiGrayscale className={styles.conxaiLogo} />
          </section>
          <header className={styles.header}>
            <div className={styles.headerSection}>
              <span>{t('Daily Report')}</span>
              <span>{formatInTimeZone(report.startTime, timezone, DATE_FORMATS.DATE)}</span>
            </div>
            <div className={styles.headerSection}>
              <span>{t('Source')}</span>
              <span>
                {project.getCameraById(report.sources.at(0))?.name || t('N/A', {ns: 'translation'})}
              </span>
            </div>
          </header>

          <section className={styles.projectInfo}>
            {project.name && (
              <p>
                <strong>{t('Project Name', {ns: 'project'})}:</strong> {project.name}
              </p>
            )}

            <p>
              <strong>{t('Project Location', {ns: 'project'})}:</strong>{' '}
              {project.settings.location.name}
            </p>
          </section>

          <div className={styles.summary} style={{pageBreakInside: 'avoid'}}>
            <div className={styles.summaryTitle}>{t('High Level Summary')}</div>
            <p>{report.summary}</p>
          </div>

          <div className={styles.blocks} style={{pageBreakInside: 'avoid'}}>
            {report.stats.section1.map((item, index) => (
              <div key={index} className={styles.block}>
                <div>{t(item.label)}</div>
                <div>{item.value}</div>
              </div>
            ))}
            {/*<div className={styles.block}>*/}
            {/*  <div>{t('Weather conditions')}</div>*/}
            {/*  <div>*/}
            {/*    {report.stats.weather.temperature} °{report.stats.weather.unit.at(0).toUpperCase()}*/}
            {/*  </div>*/}
            {/*</div>*/}

            {report.stats.section2.map((item, index) => (
              <div key={index} className={styles.block}>
                <div>{t(item.label)}</div>
                <div>{item.value}</div>
              </div>
            ))}
          </div>

          <PDFSection title={t('Site Conditions')} items={report.siteConditionsv2} />
          <PDFSection title={t('Labor')} items={report.labourv2} />
          <PDFSection title={t('Equipment utilization')} items={report.equipmentv2} />
          <PDFSection title={t('Ongoing Activities')} items={report.ongoingActivitiesv2} />

          <div className={styles.moreDetails}>
            <div>
              <Trans i18nKey={'More details are available through the following link'} t={t}>
                More details are available through the
                <a href={`${process.env.APP_URL}/project/${project.id}/status-report/${reportId}`}>
                  following link
                </a>
                .
              </Trans>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

async function handleDownloadPdf(
  pdfRef: React.RefObject<HTMLDivElement>,
  startTime: Date,
  timezone: string
) {
  const detachedDiv = document.createElement('div');
  detachedDiv.innerHTML = pdfRef.current ? pdfRef.current.innerHTML : '';
  document.body.appendChild(detachedDiv);

  const options = {
    margin: [12, 12, 12, 12],
    filename: `daily_report_${formatInTimeZone(
      startTime,
      timezone,
      DATE_FORMATS.DATE_IN_FILE
    )}.pdf`,
    image: {type: 'jpeg', quality: 0.98},
    html2canvas: {scale: 6},
    jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'},
    pagebreak: {mode: ['css', 'legacy']}
  };

  html2pdf()
    .from(detachedDiv)
    .set(options)
    .save()
    .then(() => {
      document.body.removeChild(detachedDiv);
    });
}
