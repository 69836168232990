import {FrequencyStatistic} from '@shared/types/FrequencyStatistic';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import {useMemo} from 'react';
import {TimeRange} from '@shared/ui/TimeRange';
import {formatHours} from '../lib/utils/formatHours';
import {DATE_FORMATS} from '@shared/lib/constants';
import {getHourRangeFromTimeRange, formatTime} from '@shared-app/lib';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import {useElementSize} from '@conxai/react-kit';

interface Props {
  data: FrequencyStatistic[];
  timeRange: TimeRange;
}

export function FrequencyHeatmapChart({data, timeRange}: Props) {
  const timezone = useProjectTimezone();
  const {ref, width} = useElementSize<HTMLDivElement>();
  const series: ApexAxisChartSeries = useMemo(() => {
    const [startHour, endHour] = getHourRangeFromTimeRange(timeRange);

    const series = Array.from({length: endHour - startHour}).map((_, hourIndex) => {
      const hour = startHour + hourIndex;
      const detectionDurationOfHourPerDay = data.map(statistic => statistic.data[hour]);
      return {
        name: formatTime(hour),
        data: detectionDurationOfHourPerDay
      };
    });

    return series;
  }, [data, timeRange]);
  const {t} = useTranslation();

  return (
    <div ref={ref}>
      <Chart
        series={series}
        options={getOptions(data, timezone, t)}
        type={'heatmap'}
        height={series.length * 30 + 150}
        width={width}
      />
    </div>
  );
}

function getOptions(data: FrequencyStatistic[], timezone: string, t: TFunction): ApexOptions {
  const categories = data.map(statistic =>
    formatInTimeZone(statistic.date, timezone, DATE_FORMATS.DATE)
  );

  return {
    chart: {
      id: 'frequency-statistic-heatmap-chart',
      toolbar: {
        show: false
      }
    },
    yaxis: {
      title: {text: t('Detected time (hours)')},
      min: 0,
      max: 1,
      reversed: true,
      labels: {
        formatter: (val, opts) => {
          if (opts?.series) {
            return formatHours(val);
          }
          return String(val);
        }
      }
    },
    colors: ['#008FFB'],
    xaxis: {
      title: {text: 'Days'},
      categories
    },
    dataLabels: {
      enabled: false
    }
  };
}
