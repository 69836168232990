export function PrivacyTypeBoxFill({color}: {color: string}) {
  return (
    <svg
      width="181"
      height="121"
      viewBox="0 0 181 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M157.706 98.2385H2.02344C1.47115 98.2385 1.02344 97.7908 1.02344 97.2385V51.8712V23.1641C1.02344 22.6118 1.47115 22.1641 2.02344 22.1641L26.6466 22.1641C27.7512 22.1641 28.6466 23.0595 28.6466 24.1641V50.8712C28.6466 51.4235 29.0944 51.8712 29.6466 51.8712H49.9566C50.5089 51.8712 50.9566 51.4235 50.9566 50.8712V43.9416C50.9566 42.837 51.8521 41.9416 52.9566 41.9416H71.2666C72.3712 41.9416 73.2666 41.0462 73.2666 39.9416V36.1431C73.2666 35.5908 73.7143 35.1431 74.2666 35.1431H87.7748C88.327 35.1431 88.7748 35.5908 88.7748 36.1431V46.5435C88.7748 47.0958 89.2225 47.5435 89.7748 47.5435H98.0184C98.5707 47.5435 99.0184 47.9912 99.0184 48.5435V71.6931"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <circle cx="41.9844" cy="14.0078" r="3.5" fill="#99CEFF" stroke="#4C4C4C" />
      <rect x="4.5" y="26.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="12.918" y="26.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="21.3379" y="26.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="4.5" y="34.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="12.918" y="34.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="21.3379" y="34.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="4.5" y="43.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="12.918" y="43.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="21.3379" y="43.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="53.5" y="44.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="60.918" y="44.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="68.3379" y="44.5" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <rect x="82.2227" y="36.9141" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <path
        d="M56.957 81.7339C36.7202 82.852 7.69172 82.9293 2.00915 82.9335C1.45634 82.9339 1.02344 83.3821 1.02344 83.9349V118.225C1.02344 118.777 1.47115 119.225 2.02344 119.225H177.784C178.336 119.225 178.784 118.777 178.784 118.225V70.7266C178.784 69.622 177.899 68.7266 176.794 68.7266L123.613 68.7266C96.8532 68.7266 79.479 80.4895 56.957 81.7339Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <rect x="75.459" y="36.9141" width="4.1626" height="4.98297" rx="0.5" stroke="#4C4C4C" />
      <path
        d="M1.02344 48.8565L1.02346 1.625C1.02346 1.07271 1.47117 0.625 2.02346 0.625H177.784C178.336 0.625 178.784 1.07272 178.784 1.625V109.073"
        stroke="#4C4C4C"
        strokeLinejoin="round"
      />
      <path
        d="M146.925 85.4156L154.15 74.2845C154.554 73.6615 155.472 73.6811 155.85 74.3207L162.417 85.4518C162.811 86.1184 162.33 86.96 161.556 86.96H161.291C160.445 86.96 159.982 87.9447 160.52 88.5968L163.611 92.3383C164.15 92.9904 163.686 93.9751 162.84 93.9751H145.96C145.055 93.9751 144.615 92.8678 145.275 92.2471L149.055 88.688C149.714 88.0673 149.275 86.96 148.369 86.96H147.764C146.971 86.96 146.494 86.0808 146.925 85.4156Z"
        fill="#E5F3FF"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M120.334 96.9118L126.28 88.0348C126.686 87.428 127.585 87.4474 127.965 88.0712L133.422 97.0335C133.787 97.6333 133.355 98.4017 132.653 98.4017C131.885 98.4017 131.47 99.3014 131.968 99.8858L134.27 102.587C134.824 103.236 134.362 104.235 133.509 104.235H119.785C118.872 104.235 118.436 103.112 119.111 102.497L121.775 100.066C122.42 99.4769 122.004 98.4017 121.13 98.4017C120.364 98.4017 119.908 97.5478 120.334 96.9118Z"
        fill="#E5F3FF"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M136.168 42.6004V39.6787C136.168 39.513 136.302 39.3787 136.468 39.3787H139.118C139.284 39.3787 139.418 39.513 139.418 39.6787V42.6004C139.418 42.7661 139.284 42.9004 139.118 42.9004H136.468C136.302 42.9004 136.168 42.7661 136.168 42.6004Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <mask id="path-22-inside-1_12996_229026" fill="white">
        <path d="M132.834 47.9314C132.282 47.9314 131.825 48.3822 131.917 48.9268C132.054 49.7348 132.355 50.5042 132.804 51.1764C133.435 52.1204 134.324 52.8246 135.346 53.1886C136.367 53.5526 137.469 53.5579 138.494 53.2037C139.518 52.8494 140.413 52.1538 141.052 51.2159C141.69 50.2779 142.039 49.1456 142.048 47.9803C142.058 46.815 141.727 45.6762 141.105 44.7262C140.482 43.7761 139.598 43.0634 138.579 42.6896C137.863 42.4268 137.106 42.3409 136.362 42.4329C135.831 42.4985 135.547 43.0492 135.694 43.5633L136.012 44.6768C136.168 45.225 136.773 45.5055 137.332 45.6163C137.435 45.6367 137.537 45.6654 137.638 45.7022C138.071 45.8612 138.447 46.1643 138.712 46.5683C138.977 46.9723 139.117 47.4566 139.113 47.9522C139.109 48.4478 138.961 48.9293 138.689 49.3282C138.418 49.727 138.037 50.0229 137.601 50.1735C137.166 50.3242 136.697 50.3219 136.263 50.1671C135.828 50.0123 135.45 49.7128 135.182 49.3114C135.096 49.1836 135.024 49.0476 134.964 48.9056C134.75 48.3963 134.322 47.9314 133.769 47.9314H132.834Z" />
      </mask>
      <path
        d="M132.834 47.9314C132.282 47.9314 131.825 48.3822 131.917 48.9268C132.054 49.7348 132.355 50.5042 132.804 51.1764C133.435 52.1204 134.324 52.8246 135.346 53.1886C136.367 53.5526 137.469 53.5579 138.494 53.2037C139.518 52.8494 140.413 52.1538 141.052 51.2159C141.69 50.2779 142.039 49.1456 142.048 47.9803C142.058 46.815 141.727 45.6762 141.105 44.7262C140.482 43.7761 139.598 43.0634 138.579 42.6896C137.863 42.4268 137.106 42.3409 136.362 42.4329C135.831 42.4985 135.547 43.0492 135.694 43.5633L136.012 44.6768C136.168 45.225 136.773 45.5055 137.332 45.6163C137.435 45.6367 137.537 45.6654 137.638 45.7022C138.071 45.8612 138.447 46.1643 138.712 46.5683C138.977 46.9723 139.117 47.4566 139.113 47.9522C139.109 48.4478 138.961 48.9293 138.689 49.3282C138.418 49.727 138.037 50.0229 137.601 50.1735C137.166 50.3242 136.697 50.3219 136.263 50.1671C135.828 50.0123 135.45 49.7128 135.182 49.3114C135.096 49.1836 135.024 49.0476 134.964 48.9056C134.75 48.3963 134.322 47.9314 133.769 47.9314H132.834Z"
        fill="#99CEFF"
        stroke="#4C4C4C"
        strokeWidth="2"
        mask="url(#path-22-inside-1_12996_229026)"
      />
      <rect
        x="156.166"
        y="31.6614"
        width="5.5"
        height="29.5217"
        rx="0.5"
        fill="white"
        stroke="#4C4C4C"
      />
      <rect
        x="134.5"
        y="28.1396"
        width="6.58333"
        height="3.69565"
        rx="0.5"
        fill="#E5F3FF"
        stroke="#4C4C4C"
      />
      <rect
        x="136.666"
        y="31.6614"
        width="2.25"
        height="7.21739"
        rx="0.5"
        fill="white"
        stroke="#4C4C4C"
      />
      <rect
        x="164.834"
        y="29.3135"
        width="5.5"
        height="8.3913"
        rx="0.5"
        fill="#E5F3FF"
        stroke="#4C4C4C"
      />
      <path
        d="M146.416 64.975C146.416 64.7772 146.533 64.598 146.713 64.5179L151.883 62.226C151.947 62.1977 152.016 62.1831 152.086 62.1831H164.693C164.753 62.1831 164.812 62.1939 164.869 62.215L171.092 64.5492C171.287 64.6224 171.416 64.8089 171.416 65.0173V67.7266C171.416 68.0027 171.192 68.2266 170.916 68.2266H146.916C146.64 68.2266 146.416 68.0027 146.416 67.7266V64.975Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
      />
      <path
        d="M157.964 16.2196L133.435 24.4469C132.449 24.7774 132.56 26.2056 133.584 26.3807L133.819 26.4209C133.991 26.4502 134.167 26.4343 134.331 26.3747L158.441 17.5828C158.726 17.4789 158.916 17.2078 158.916 16.9043C158.916 16.4108 158.432 16.0627 157.964 16.2196Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M146.516 26.3673L157.304 20.7638C157.629 20.5952 157.833 20.2598 157.833 19.894C157.833 19.1513 157.039 18.6785 156.386 19.0323L145.697 24.8234C144.9 25.2553 145.207 26.4657 146.114 26.4657C146.254 26.4657 146.392 26.4319 146.516 26.3673Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M168.913 28.4682L160.177 18.2589C160.063 18.1258 160 17.9562 160 17.781C160 17.1117 160.822 16.7906 161.275 17.2824L170.388 27.1571C170.972 27.7893 170.523 28.8134 169.663 28.8134C169.374 28.8134 169.1 28.6873 168.913 28.4682Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M161.083 32.3352L157.705 35.0809C157.212 35.4811 157.212 36.2328 157.705 36.633L160.128 38.6026C160.621 39.0028 160.621 39.7545 160.128 40.1547L157.705 42.1244C157.212 42.5246 157.212 43.2763 157.705 43.6765L160.128 45.6461C160.621 46.0463 160.621 46.798 160.128 47.1982L157.705 49.1679C157.212 49.5681 157.212 50.3197 157.705 50.7199L160.128 52.6896C160.621 53.0898 160.621 53.8415 160.128 54.2417L157.705 56.2113C157.212 56.6115 157.212 57.3632 157.705 57.7634L161.083 60.5091"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M157.916 26.4656H126.404C126.096 26.4656 125.805 26.6074 125.616 26.8501L123.797 29.1796C123.54 29.5079 123.774 29.9873 124.191 29.9873H157.916C158.468 29.9873 158.916 29.5396 158.916 28.9873V27.4656C158.916 26.9133 158.468 26.4656 157.916 26.4656Z"
        fill="#99CEFF"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M161.86 22.8778L160.298 16.5577C160.202 16.1716 159.856 15.9004 159.458 15.9004C159.061 15.9004 158.714 16.1716 158.619 16.5577L157.057 22.8778C156.901 23.5083 157.378 24.1178 158.027 24.1178H160.889C161.539 24.1178 162.016 23.5083 161.86 22.8778Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M153.774 29.4875C153.327 29.4875 153.105 28.9452 153.423 28.6313L157.351 24.7617C157.445 24.6696 157.571 24.6179 157.702 24.6179H162.251C162.527 24.6179 162.751 24.8418 162.751 25.1179V28.9875C162.751 29.2636 162.527 29.4875 162.251 29.4875H153.774Z"
        fill="white"
        stroke="#4C4C4C"
      />
      <rect
        x="162.666"
        y="26.9656"
        width="9.83333"
        height="2.52174"
        rx="0.5"
        fill="#99CEFF"
        stroke="#4C4C4C"
      />
      <rect
        x="155.084"
        y="59.8352"
        width="7.66667"
        height="2.52174"
        rx="0.5"
        fill="#E5F3FF"
        stroke="#4C4C4C"
      />
      <rect
        x="155.084"
        y="29.3135"
        width="7.66667"
        height="2.52174"
        rx="0.5"
        fill="#E5F3FF"
        stroke="#4C4C4C"
      />
      <path
        d="M31.8785 80.2312H17.6738C17.1215 80.2312 16.6738 79.7835 16.6738 79.2312V76.9761C16.6738 76.6203 16.8628 76.2914 17.1702 76.1122L18.6302 75.2611C18.7831 75.172 18.9569 75.125 19.1338 75.125H31.8785C32.4308 75.125 32.8785 75.5727 32.8785 76.125V76.8271V79.2312C32.8785 79.7835 32.4308 80.2312 31.8785 80.2312Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M36.1912 74.5677H35.3378C34.713 74.5677 34.2411 74.001 34.3544 73.3865L36.9163 59.4828C37.0038 59.0084 37.4174 58.6641 37.8998 58.6641H40.2536C40.8059 58.6641 41.2536 59.1118 41.2536 59.6641V60.9545C41.2536 61.5068 40.8059 61.9545 40.2536 61.9545H39.6178C39.1164 61.9545 38.6925 62.3258 38.6265 62.8229L37.1825 73.6993C37.1165 74.1964 36.6926 74.5677 36.1912 74.5677Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M37.5724 78.1072H33.8789C33.3266 78.1072 32.8789 77.6595 32.8789 77.1072V75.7031C32.8789 75.1508 33.3266 74.7031 33.8789 74.7031H37.5724C38.1247 74.7031 38.5724 75.1508 38.5724 75.7031V77.1072C38.5724 77.6595 38.1247 78.1072 37.5724 78.1072Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M29.1562 79.7904V79.1138C29.1562 78.5615 29.604 78.1138 30.1563 78.1138H39.1199V65.1953C39.1199 64.643 39.5676 64.1953 40.1199 64.1953H44.5985C45.9417 64.1953 47.1213 65.0882 47.4859 66.3811L48.455 69.8177C48.5048 69.9943 48.5301 70.177 48.5301 70.3605V78.1138C48.8358 78.1138 49.0836 78.3616 49.0836 78.6673V79.7904C49.0836 80.3427 48.6359 80.7904 48.0836 80.7904H30.1562C29.604 80.7904 29.1562 80.3427 29.1562 79.7904Z"
        fill="white"
      />
      <path
        d="M39.1199 78.1138H30.1563C29.604 78.1138 29.1562 78.5615 29.1562 79.1138V79.7904C29.1562 80.3427 29.604 80.7904 30.1562 80.7904H48.0836C48.6359 80.7904 49.0836 80.3427 49.0836 79.7904V78.6673C49.0836 78.3616 48.8358 78.1138 48.5301 78.1138V78.1138M39.1199 78.1138V65.1953C39.1199 64.643 39.5676 64.1953 40.1199 64.1953H44.5985C45.9417 64.1953 47.1213 65.0882 47.4859 66.3811L48.455 69.8177C48.5048 69.9943 48.5301 70.177 48.5301 70.3605V78.1138M39.1199 78.1138H48.5301"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M41.2539 70.8013V66.5781C41.2539 66.0258 41.7016 65.5781 42.2539 65.5781H44.5073C45.4199 65.5781 46.2168 66.1959 46.4442 67.0797L47.3377 70.5521C47.5005 71.1849 47.0227 71.8013 46.3692 71.8013H42.2539C41.7016 71.8013 41.2539 71.3536 41.2539 70.8013Z"
        fill="#CCE6FF"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M33.2518 65.6332L19.3879 67.4018C19.0098 67.45 18.6218 67.2796 18.5251 66.9105C18.1646 65.535 18.796 63.218 20.3493 62.746C21.8569 62.288 24.801 59.7373 28.5956 59.2532C31.8742 58.835 33.7086 62.1968 34.0504 64.6642C34.1184 65.1556 33.7439 65.5704 33.2518 65.6332Z"
        fill="#99CEFF"
        stroke="#4C4C4C"
      />
      <path
        d="M17.8433 67.5997L29.8062 66.0736C30.2242 66.0203 30.5955 65.6933 30.4641 65.2923C30.1131 64.2215 28.4708 63.3348 26.9131 62.7505C25.7311 62.3072 23.3143 60.6252 20.3654 61.0013C17.9338 61.3115 17.2293 63.8345 16.7243 66.4152C16.5936 67.0835 17.1663 67.686 17.8433 67.5997Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
      />
      <ellipse cx="21.054" cy="80.2364" rx="3.06575" ry="2.97861" fill="white" stroke="#4C4C4C" />
      <ellipse cx="21.0541" cy="80.2375" rx="1.31389" ry="1.27655" fill="white" stroke="#4C4C4C" />
      <ellipse cx="28.0618" cy="80.2364" rx="3.06575" ry="2.97861" fill="white" stroke="#4C4C4C" />
      <ellipse cx="28.0619" cy="80.2375" rx="1.31389" ry="1.27655" fill="white" stroke="#4C4C4C" />
      <ellipse cx="43.8275" cy="80.2364" rx="3.06575" ry="2.97861" fill="white" stroke="#4C4C4C" />
      <ellipse cx="43.8276" cy="80.2375" rx="1.31389" ry="1.27655" fill="white" stroke="#4C4C4C" />
      <path
        d="M14.9219 72.4642V68.6093C14.9219 68.1099 15.2904 67.687 15.7852 67.6187L34.2137 65.0765C34.8819 64.9843 35.4479 65.5667 35.3366 66.232L34.0818 73.736C34.0012 74.218 33.5841 74.5711 33.0955 74.5711H16.9707C16.6961 74.5711 16.4337 74.4582 16.2448 74.2589L15.196 73.1521C15.02 72.9663 14.9219 72.7201 14.9219 72.4642Z"
        fill="white"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M51.6808 94.5859C31.5174 94.9167 6.86259 97.1537 1.91983 97.6187C1.40747 97.6668 1.0332 98.0956 1.0332 98.6102V118.223C1.0332 118.775 1.48092 119.223 2.0332 119.223H177.794C178.346 119.223 178.794 118.775 178.794 118.223V106.232C178.794 105.127 177.906 104.244 176.804 104.332C170.51 104.834 155.462 107.055 127.273 107.055C100.513 107.055 74.2029 94.2165 51.6808 94.5859Z"
        fill="#D9D9D9"
        stroke="#4C4C4C"
        strokeLinecap="round"
      />
      <path
        d="M82.4852 111.015H85.5166H90.5311C91.2864 111.015 90.5311 109.596 90.761 108.972C90.9449 108.473 90.1808 108.436 89.7758 108.48C89.4145 102.415 88.9088 90.3584 89.7758 90.6474C90.6427 90.9363 92.0417 89.695 92.6329 89.0382C92.195 89.6184 91.628 90.9429 92.8628 91.5997C94.0976 92.2565 94.9536 90.4503 95.2273 89.4651L95.9497 80.8938L90.8923 71.9475L87.8543 71.053L86.853 70.7192C86.7242 70.3098 86.853 70.3098 86.853 69.2453C89.0204 68.5655 89.7758 66.5959 89.9071 66.0517C92.6329 65.7635 91.2325 63.2676 90.5968 63.2676C90.5968 62.7577 90.9142 60.7249 90.8923 59.5804L93.027 59.2553C92.6198 58.3184 92.1111 58.1797 91.4506 57.2082C90.9514 54.1807 88.5935 52.6897 87.4769 52.3226L86.853 51.4688H82.1568C81.7499 52.1313 81.7499 51.8444 81.7499 52.1313C78.7366 53.0484 77.8822 55.898 77.8316 57.2082L76.3391 58.6002C76.3391 58.6002 75.6024 59.2553 78.1064 59.2553C77.8316 60.7693 78.7401 61.7866 78.4695 63.2676C76.4637 64.414 78.4695 66.8733 79.3753 66.0517C79.8539 67.755 81.648 68.8905 82.4852 69.2453V70.3098L80.8492 70.7192L77.41 71.9475L72.4584 80.6618C72.3755 83.564 72.6475 89.6869 74.3988 90.9605C76.5879 92.5526 75.8913 91.3088 76.3391 91.3088C76.7869 91.3088 76.8367 89.0699 76.9362 89.7167C77.0158 90.2341 78.1966 90.4298 78.777 90.463V108.722C78.6775 108.573 78.3491 108.364 77.8316 108.722C77.3142 109.08 77.7992 110.4 78.1064 111.015H82.4852Z"
        fill={color}
      />
      <rect x="72.4453" y="51.4688" width="23.5044" height="59.5462" fill={color} />
    </svg>
  );
}
