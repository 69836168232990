import {TagEnum} from '@shared/types/TagEnum';
import {useTranslationTags} from '@shared/lib/hooks';
import {useAnchorElement} from '@conxai/react-kit';
import {Tag} from '@shared/types/Tag';
import {TagChip} from '../TagChip/TagChip';
import TagIcon from '@shared/ui/TagIcon';
import styles from './TagChipWithPopover.module.scss';
import {Popover} from '@shared/ui/Popover';

interface TagChipProp {
  tag: Tag;
  onDelete?: (tagStringId: TagEnum) => void;
  selected?: boolean;
}

export function TagChipWithPopover({tag, selected, onDelete}: TagChipProp) {
  const {t} = useTranslationTags();
  const {anchor, setAnchor, clearAnchor} = useAnchorElement();

  const open = Boolean(anchor);
  const id = `tag-popover-${tag.key}`;

  return (
    <div
      onMouseEnter={setAnchor}
      onMouseLeave={clearAnchor}
      aria-owns={open ? id : undefined}
      aria-haspopup="true"
    >
      <TagChip tag={tag} selected={selected} onDelete={onDelete} />
      <Popover
        id={id}
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={clearAnchor}
        disableRestoreFocus
      >
        <div className={styles.popoverContent}>
          <div className={styles.popoverContent__icon}>
            <TagIcon stringId={tag.key} />
          </div>
          <div className={styles.popoverContent__description}>{t('description', tag.key)}</div>
        </div>
      </Popover>
    </div>
  );
}
