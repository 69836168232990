import {Tabs, Tab} from '@shared/ui/Tabs';
import {Typography} from '@shared/ui/Typography';
import styles from './FrequencyGroupByTabs.module.scss';
import {useTranslation} from 'react-i18next';

interface FrequencyGroupByTabsProps {
  onChange: (index: number) => void;
  selectedTabIndex: number;
  title: string;
}

function FrequencyGroupByTabs({title, onChange, selectedTabIndex}: FrequencyGroupByTabsProps) {
  const handleChange = (_: React.SyntheticEvent, index: number) => {
    onChange(index);
  };

  const {t} = useTranslation();

  return (
    <div className={styles.container}>
      <Typography variant="h4">{title}</Typography>
      <Tabs onChange={handleChange} value={selectedTabIndex} className={styles.tabs}>
        <Tab className={styles.tab} label={t('Date Range')}></Tab>
        <Tab className={styles.tab} label={t('Week Days')}></Tab>
      </Tabs>
    </div>
  );
}

export default FrequencyGroupByTabs;
