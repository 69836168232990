import {useTranslation} from 'react-i18next';
import {useMutation} from '@shared/lib/hooks';
import {ProjectService, useCurrentProject} from '@entities/project';
import {PrivacySettings} from '@shared-app/types';

export function useUpdatePrivacySettings() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('settings');
  const listQueryOptions = {queryKey: ['settings', 'privacy', projectId]};

  const {
    mutate: updatePrivacySettings,
    isLoading: isSaving,
    isSuccess: isSaved
  } = useMutation<PrivacySettings>({
    mutationFn: privacySettings => {
      return ProjectService.updatePrivacySettings(projectId, privacySettings).catch(() => {
        throw new Error(t('Error occurred. Please try to save again.'));
      });
    },
    options: listQueryOptions
  });

  return {
    updatePrivacySettings,
    isSaving,
    isSaved
  };
}
