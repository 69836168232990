import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Alert} from '@shared/ui/Alert';
import {ZoneCard} from './ZoneCard';
import {useSmartImage} from '@entities/smartImage';
import {ConfigurationCanvas} from './ConfigurationCanvas';
import {checkPolygonsIntersect} from '../lib/utils/checkPolygonsIntersect';
import {BorderButton, Button} from '@shared/ui/Button';
import {useUpdateZoneSettings, useZoneSettings} from '@entities/project/lib/hooks';
import {ZoneData} from '@shared-app/types';
import {ZONE_COLORS} from '../lib/constants/zoneColors';
import styles from './ZoneConfiguration.module.scss';
import {ToastService} from '@shared/lib/services';
import {CircularLoader} from '@shared/ui/Loader';
import {ZoneConfigurationSkeleton} from './ZoneConfigurationSkeleton';

interface ZoneConfigurationProps {
  imageId: string;
  cameraId: string;
}

export function ZoneConfiguration({imageId, cameraId}: ZoneConfigurationProps) {
  const {t} = useTranslation('settings');
  const [activeZone, setActiveZone] = useState<'exclusion' | 'ppe'>('exclusion');
  const {
    data: initialPolygons,
    isFetching: isZoneSettingsFetching,
    isError
  } = useZoneSettings(cameraId);
  const {data: image, isFetching} = useSmartImage(imageId);
  const {updateZoneSettings, isSaved, isSaving} = useUpdateZoneSettings(cameraId);
  const [areZonesOverlapped, setAreZonesOverlapped] = useState(false);
  const [zonesData, setZonesData] = useState<ZoneData[]>([]);

  const handleSave = () => {
    updateZoneSettings(zonesData);
  };

  const handleReset = () => {
    setZonesData([...initialPolygons]);
  };

  useEffect(() => {
    if (zonesData.length > 1) {
      setAreZonesOverlapped(checkPolygonsIntersect(zonesData));
    }
  }, [zonesData]);

  useEffect(() => {
    if (isError) {
      ToastService.error(t('Failed to get zone settings'));
    }
  }, [isError, t]);

  useEffect(() => {
    if (initialPolygons) {
      setZonesData(initialPolygons);
    }
  }, [initialPolygons]);

  useEffect(() => {
    if (isSaved) {
      ToastService.success(t('Zone settings saved successfully'));
    }
  }, [isSaved, t]);

  if (isFetching || isZoneSettingsFetching) {
    return <ZoneConfigurationSkeleton />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <div className={styles.cards}>
          <ZoneCard
            color={ZONE_COLORS.exclusion.border}
            onClick={() => setActiveZone('exclusion')}
            selected={activeZone === 'exclusion'}
            title={t('Exclusion zones')}
            description={t(
              'Zones will excluded be from AI detection and blurred according to project privacy settings.'
            )}
          />
          <ZoneCard
            color={ZONE_COLORS.ppe.border}
            onClick={() => setActiveZone('ppe')}
            selected={activeZone === 'ppe'}
            title={t('PPE detection zones')}
            description={t('Persons without proper PPE will be detected in those areas.')}
          />
          {areZonesOverlapped && (
            <Alert severity="warning" className={styles.overlapWarning}>
              {t('Some zones are overlapped with each other')}
            </Alert>
          )}
        </div>
        <div className={styles.actions}>
          <BorderButton size="small" onClick={handleReset} fullWidth>
            {t('Reset', {ns: 'translation'})}
          </BorderButton>
          <Button
            size="small"
            variant="contained"
            onClick={handleSave}
            startIcon={isSaving ? <CircularLoader size="1em" /> : undefined}
            disabled={isSaving}
            fullWidth
          >
            {t('Save', {ns: 'translation'})}
          </Button>
        </div>
      </div>
      <ConfigurationCanvas
        image={image}
        zone={activeZone}
        zonesData={zonesData}
        setZonesData={setZonesData}
      />
    </div>
  );
}
