import {FocusEvent, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {instanceToInstance} from 'class-transformer';
import classNames from 'classnames';
import {Finding} from '@shared-app/types';
import {Accordion, AccordionDetails, AccordionSummary, IconButton} from '@mui/material';
import {DeleteIcon, KeyboardArrowDownIcon, WarningIcon} from '@shared/ui/icons';
import {InputFindingListItem} from './InputFindingListItem';
import styles from './FindingsList.module.scss';
import {preventDefault} from '@shared-app/lib';

interface ObservationListProps {
  onChange: (observations: Finding[]) => void;
  observations: Finding[];
  editable?: boolean;
}

export function ObservationList({observations, onChange, editable}: ObservationListProps) {
  const {t} = useTranslation('statusReport');
  const ref = useRef<HTMLUListElement>();

  const handleAddObservation = (observation: Finding) => {
    onChange([...observations, observation]);
  };

  const handleObservationChange = (index: number) => (event: FocusEvent<HTMLSpanElement>) => {
    const value = event.target.textContent;
    const modified = observations[index];
    const modifiedInstance = instanceToInstance(modified);
    modifiedInstance.message = value;

    onChange([...observations.slice(0, index), modifiedInstance, ...observations.slice(index + 1)]);
  };

  const handleRemoveObservation = (index: number) => {
    onChange([...observations.slice(0, index), ...observations.slice(index + 1)]);
  };

  if (observations.length === 0) {
    if (!editable) return null;

    return (
      <ul className={styles.emptyObservations} ref={ref}>
        <InputFindingListItem
          className={classNames(styles.editable, styles.addNewObservations)}
          onAdd={handleAddObservation}
          indicator="warning"
          placeholder={t('Type here to add observation')}
        />
      </ul>
    );
  }

  return (
    <Accordion className={styles.observationAccordion} defaultExpanded>
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon className={styles.expandIcon} />}
        className={styles.observationTitleWrapper}
      >
        <div className={styles.obserationTitle}>
          <WarningIcon className={styles.warningIcon} />
          {observations.length} {t('Observations')}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <ul className={styles.obserations} ref={ref}>
          {observations.map((observation, index) => (
            <li className={classNames({[styles.editable]: editable})} key={index}>
              <span
                className={styles.message}
                data-id={index}
                contentEditable={editable}
                onBlur={handleObservationChange(index)}
                onDrop={preventDefault}
                suppressContentEditableWarning
              >
                {observation.message}
              </span>
              {editable && (
                <IconButton
                  className={styles.deleteButton}
                  color="primary"
                  onClick={() => handleRemoveObservation(index)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </li>
          ))}
          {editable && (
            <InputFindingListItem
              className={styles.editable}
              onAdd={handleAddObservation}
              indicator="warning"
              placeholder={t('Type here to add observation')}
            />
          )}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
}
