import {useTranslation} from 'react-i18next';
import {NavLink, useParams} from 'react-router-dom';
import {Breadcrumbs} from '@shared/ui/Breadcrumbs';
import {Typography} from '@shared/ui/Typography';
import {ROUTES} from '@shared/lib/constants';
import {useCurrentProject, useProjectImageStatistics} from '@entities/project';
import {CameraSettingsInfo} from '@features/camera/cameraSettingsInfo';
import {ZoneConfiguration} from '@features/camera/zoneConfiguration';
import {CameraDetailsSkeleton} from './CameraDetailsSkeleton';
import styles from './CameraDetailsPage.module.scss';

export function CameraDetailsPage() {
  const {project} = useCurrentProject();
  const {id: projectId} = project;
  const {cameraId} = useParams();
  const {t} = useTranslation('settings');

  const {
    data: projectImageStatistics,
    isFetching: isFetchingProjectImageStatistics,
    isError
  } = useProjectImageStatistics();

  if (isFetchingProjectImageStatistics || !projectImageStatistics) {
    return <CameraDetailsSkeleton />;
  }

  if (isError) {
    return t('Something went wrong', {ns: 'translation'});
  }

  const camerasObject = project.getCamerasObject();
  const camera = camerasObject[cameraId];
  const cameraStatistics = projectImageStatistics.camerasStatistics.find(
    cameraStats => cameraStats.id === camera.id
  );

  return (
    <div className={styles.container}>
      <Breadcrumbs>
        <NavLink to={ROUTES.PROJECT.SETTINGS.CAMERA.ROOT.withParams({projectId})}>
          {t('Camera List')}
        </NavLink>
        <Typography variant="body1">{t('Camera Details')}</Typography>
      </Breadcrumbs>
      <CameraSettingsInfo
        camera={camera}
        cameraStatistics={cameraStatistics}
        camerasObject={camerasObject}
      />
      <ZoneConfiguration imageId={cameraStatistics.lastImageId} cameraId={cameraId} />
    </div>
  );
}
