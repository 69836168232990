export enum PrivacyType {
  CONTOUR_BLUR = 'contourBlur',
  CONTOUR_FILL = 'contourFill',
  BOX_BLUR = 'boxBlur',
  BOX_FILL = 'boxFill'
}

export interface PrivacySettings {
  privacyType?: PrivacyType;
  opacity: number;
  color: string;
}
