import {Line, Circle, Group} from 'react-konva';
import Konva from 'konva';
import {Vector2d} from 'konva/lib/types';

export const dragBoundFunc = (
  stageWidth: number,
  stageHeight: number,
  vertexRadius: number,
  pos: Vector2d
) => {
  let x = pos.x;
  let y = pos.y;
  if (pos.x + vertexRadius > stageWidth) x = stageWidth;
  if (pos.x - vertexRadius < 0) x = 0;
  if (pos.y + vertexRadius > stageHeight) y = stageHeight;
  if (pos.y - vertexRadius < 0) y = 0;
  return {x, y};
};
export const minMax = (points: number[]) => {
  return points.reduce(
    (acc, val) => {
      acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
      acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
      return acc;
    },
    [] satisfies [number, number][]
  );
};

interface PolygonAnnotationProps {
  points: [number, number][];
  flattenedPoints: number[];
  isFinished: boolean;
  name: string;
  handleMouseOverStartPoint?: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  handleMouseOutStartPoint?: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  onClick?: (event: Konva.KonvaEventObject<MouseEvent>) => void;
  fill: string;
  stroke: string;
  selected?: boolean;
}

const PolygonAnnotation = (props: PolygonAnnotationProps) => {
  const {
    points,
    flattenedPoints,
    isFinished,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    name,
    fill,
    stroke,
    selected,
    onClick
  } = props;
  const vertexRadius = 6;

  return (
    <Group name={name} draggable={false} onMouseDown={onClick}>
      <Line
        points={flattenedPoints}
        stroke={stroke}
        strokeWidth={selected ? 8 : 4}
        closed={isFinished}
        fill={fill}
      />
      {selected &&
        points.map((point, index) => {
          const x = point[0] - vertexRadius / 2;
          const y = point[1] - vertexRadius / 2;
          const startPointAttr =
            index === 0
              ? {
                  hitStrokeWidth: 12,
                  onMouseOver: handleMouseOverStartPoint,
                  onMouseOut: handleMouseOutStartPoint
                }
              : null;
          return (
            <Circle
              key={index}
              x={x}
              y={y}
              radius={vertexRadius}
              fill="white"
              stroke={stroke}
              strokeWidth={4}
              draggable={false}
              {...startPointAttr}
            />
          );
        })}
    </Group>
  );
};

export {PolygonAnnotation};
