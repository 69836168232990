import {FrequencyStatistic} from '@shared/types/FrequencyStatistic';
import Chart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';
import {TimeRange} from '@shared/ui/TimeRange';
import {useMemo} from 'react';
import {padAround, useElementSize} from '@conxai/react-kit';
import {WEEK_DAYS} from '@shared/lib/constants';
import {getDetectionHoursPerWeek, getColumnChartTickAmount} from '../lib/utils';
import {formatHours} from '../lib/utils/formatHours';
import {getHourRangeFromTimeRange} from '@shared-app/lib';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';

export interface WeekDayFrequencyColumnsChartProps {
  data: FrequencyStatistic[];
  timeRange: TimeRange;
}

export function WeekDayFrequencyColumnsChart({data, timeRange}: WeekDayFrequencyColumnsChartProps) {
  const {ref, width} = useElementSize<HTMLDivElement>();
  const series = useMemo(() => getSeriesFromData(data, timeRange), [data, timeRange]);

  const maxHoursInSeries = useMemo(
    () => Math.max(...series.map(({data}) => Math.max(...data))),
    [series]
  );

  const {t} = useTranslation();

  const options = useMemo(() => getOptions(maxHoursInSeries, t), [maxHoursInSeries, t]);

  return (
    <div ref={ref}>
      <Chart series={series} options={options} type={'bar'} height={300} width={width} />
    </div>
  );
}

// we will add empty values to data so that chart can align in center with smaller bars
// Just showing 7 bars in full-width, makes each bar very bigger
const REQUIRED_SERIES_LENGTH = 12;

function getSeriesFromData(data: FrequencyStatistic[], timeRange: TimeRange) {
  const [startHour, endHour] = getHourRangeFromTimeRange(timeRange);
  const totalHoursPerWeek = getDetectionHoursPerWeek(data, startHour, endHour);
  return [
    {
      name: '',
      data: padAround(totalHoursPerWeek, REQUIRED_SERIES_LENGTH, 0)
    }
  ];
}

const getOptions = (yAxisMaxValue: number, t: TFunction): ApexOptions => ({
  chart: {
    id: 'weekday-frequency-statistic-columns-chart',
    selection: {
      enabled: true,
      type: 'x'
    },
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '75%'
    }
  },
  yaxis: {
    title: {text: t('Detected time (hours)')},
    ...getColumnChartTickAmount(yAxisMaxValue),
    labels: {
      minWidth: 20,
      formatter: formatHours
    },
    min: 0
  },
  xaxis: {
    tickPlacement: 'on',
    categories: padAround(WEEK_DAYS, REQUIRED_SERIES_LENGTH, '')
  },
  dataLabels: {
    enabled: false
  }
});
